import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/store/base-query';
import serverRoutes from '@/utils/server-routes';
import { NgrammRequest, NgrammRequestEdit, NgrammResponse } from '@/interfaces/ngram.type';
import { Pagination } from '@/interfaces/common.type';
import dictionaryApi from '@/store/api/dictionary.api';

const ngrammApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'ngrammApi',
  tagTypes: ['NGRAMM'],
  endpoints: (build) => ({
    /* Добавление n-граммы */
    addNgramm: build.mutation<NgrammResponse, NgrammRequest>({
      query: (body) => ({
        url: `${serverRoutes.ngramm}?model_id=${body.model_id}`,
        method: 'POST',
        body: { temp_id: body.temp_id, weight: body.weight, value: body.value },
      }),
      invalidatesTags: ['NGRAMM'],
    }),
    /* Редактирование n-граммы */
    editNgramm: build.mutation<NgrammResponse, NgrammRequestEdit>({
      query: (body) => ({
        url: `${serverRoutes.ngramm}?model_id=${body.model_id}&ngram_id=${body.ngram_id}`,
        method: 'PUT',
        body: { weight: body.weight },
      }),
      invalidatesTags: ['NGRAMM'],
    }),
    /* Удаление n-граммы */
    deleteNgramm: build.mutation<void, { model_id: string; ngram_id: string }>({
      query: (params) => ({
        url: serverRoutes.ngramm,
        method: 'DELETE',
        params,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(dictionaryApi.endpoints?.currentEditingWords.initiate({}));
      },
      invalidatesTags: ['NGRAMM'],
    }),
    /* Список ngramm в редактируемой модели */
    ngrammBulk: build.query<string, Pagination>({
      query: (params) => ({
        url: serverRoutes.ngrammBulk,
        params,
      }),
      providesTags: ['NGRAMM'],
    }),
  }),
});

export const {
  useAddNgrammMutation,
  useEditNgrammMutation,
  useDeleteNgrammMutation,
  useNgrammBulkQuery,
} = ngrammApi;

export default ngrammApi;
