import { IconNameType } from '@/components/ui/icons/icon-name.type';
import navigationRoutes, { RoutePathKey } from '@/utils/navigation-routes';

export type MenuBarItem = {
  id: number;
  name: string;
  icon: IconNameType;
  iconSize?: number;
  iconSizeHidden?: number;
  routeEnd?: boolean;
  route: (typeof navigationRoutes)[RoutePathKey];
  tag?: string;
  soon?: boolean;
};
export type MenuBarList = {
  id: number;
  title?: string;
  menuItems: Array<MenuBarItem>;
};

export type MenuSecondBarList = {
  id: number;
  name: string;
  routeEnd?: boolean;
  route: (typeof navigationRoutes)[RoutePathKey];
};

export const menuBarData: Array<MenuBarList> = [
  {
    id: 1,
    title: 'main',
    menuItems: [
      {
        id: 1,
        name: 'model',
        icon: 'HomeIcon',
        route: navigationRoutes.main,
        routeEnd: true,
      },
      {
        id: 2,
        name: 'editor',
        icon: 'PlayCircleIcon',
        route: navigationRoutes.editor,
      },
    ],
  },
];

export const secondMenuList: Array<MenuSecondBarList> = [
  {
    id: 1,
    name: 'Speech Analytics',
    routeEnd: true,
    route: navigationRoutes.main,
  },
];
