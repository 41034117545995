import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from '@ui';

import NotAuthBlock from './blocks/not-auth.block';

import useQuery from '@/hooks/use-query';
import { useHashLoginMutation } from '@/store/api/auth.api';
import navigationRoutes from '@/utils/navigation-routes';
import { loginSession } from '@/utils/auth';

const HashAuthPage = () => {
  const [postHashLogin, { isLoading, isSuccess }] = useHashLoginMutation();
  const navigate = useNavigate();
  const query = useQuery();
  useEffect(() => {
    if (typeof query.get('hash') === 'string' && !isLoading && !isSuccess) {
      postHashLogin({ hash: query.get('hash') as string })
        .unwrap()
        .then((result) => {
          console.log(result);
          loginSession(result);
          if (result.detail === 'OK') window.location.href = navigationRoutes.main;
        })
        .catch(() => {
          toast.error('Неверный HASH');
          navigate(navigationRoutes.hashAuth);
        });
    }
  });
  if (isLoading) return <Loading />;
  return <NotAuthBlock />;
};

export default HashAuthPage;
