import { AUTH_KEY, LAST_URL } from '@/utils/constants';
import navigationRoutes from '@/utils/navigation-routes';
import config from '@/utils/config';
import { AuthResponse } from '@/interfaces/auth.type';
type StorageDataType = {
  detail: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
};
const authStorage = {
  signIn: async (tokens: StorageDataType) => {
    if (tokens) {
      localStorage.setItem(AUTH_KEY, JSON.stringify(tokens));
    }
  },
  logOut: async () => {
    localStorage.removeItem(AUTH_KEY);
  },
};
const controller = new AbortController();
export const logoutSession = () => {
  if (config.appTheme === 'rtk' && config.rtkAuthUrl) {
    localStorage.clear();
    localStorage.setItem(LAST_URL, window.location.pathname);
    const path = window.location.pathname.replace(/^\//, '');
    window.location.replace(config.rtkAuthUrl + `?context=${path}`);
  } else {
    localStorage.clear();
    localStorage.setItem(LAST_URL, window.location.pathname);
    window.location.href = window.location.origin + navigationRoutes.login;
  }
  controller.abort();
};

export const loginSession = (data: AuthResponse) => {
  localStorage.setItem(AUTH_KEY, JSON.stringify(data));
  window.location.href = window.location.pathname;
};
export default authStorage;
