import layout from './layout.json';
import notFound from './notFound.json';
import login from './login.json';
import mainpagetable from './mainPageTable.json';
import modelViewPage from './modelViewPage.json';
import editor from './editor.json';
export default {
  login,
  notFound,
  layout,
  mainpagetable,
  editor,
  modelViewPage,
};
