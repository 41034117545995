import React from 'react';
import { Route } from 'react-router-dom';

import navigationRoutes from '@/utils/navigation-routes';
import { ModelOutlet, ModelPage, ModelViewPage } from '@/pages/model';
import { BreadcrumbsItem } from '@/pages/layout';

export const modelRoute = (
  <Route path={`${navigationRoutes.main}/model`} element={<ModelOutlet />}>
    <Route
      index
      element={<ModelPage />}
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.main}`} crumb="model" />,
      }}
    />
    <Route
      path=":model_id"
      element={<ModelViewPage />}
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.model}`} crumb="current_model" />,
      }}
    />
  </Route>
);
