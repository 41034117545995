import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const CalendarEmptyIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="currentColor"
          d="M15.188 2.25H13.5V.422A.423.423 0 0013.078 0h-.281a.423.423 0 00-.422.422V2.25h-6.75V.422A.423.423 0 005.203 0h-.281A.423.423 0 004.5.422V2.25H2.812c-.931 0-1.687.756-1.687 1.688v12.374c0 .932.756 1.688 1.688 1.688h12.374c.932 0 1.688-.756 1.688-1.688V3.938c0-.931-.756-1.687-1.688-1.687zM2.812 3.375h12.376c.309 0 .562.253.562.563v1.687H2.25V3.937c0-.309.253-.562.563-.562zm12.376 13.5H2.812a.564.564 0 01-.562-.563V6.75h13.5v9.563c0 .309-.253.562-.563.562zm-8.86-5.625H4.922a.423.423 0 01-.422-.422V9.422c0-.232.19-.422.422-.422h1.406c.232 0 .422.19.422.422v1.406c0 .232-.19.422-.422.422zm3.375 0H8.297a.423.423 0 01-.422-.422V9.422c0-.232.19-.422.422-.422h1.406c.232 0 .422.19.422.422v1.406c0 .232-.19.422-.422.422zm3.375 0h-1.406a.423.423 0 01-.422-.422V9.422c0-.232.19-.422.422-.422h1.406c.232 0 .422.19.422.422v1.406c0 .232-.19.422-.422.422zm-3.375 3.375H8.297a.423.423 0 01-.422-.422v-1.406c0-.232.19-.422.422-.422h1.406c.232 0 .422.19.422.422v1.406c0 .232-.19.422-.422.422zm-3.375 0H4.922a.423.423 0 01-.422-.422v-1.406c0-.232.19-.422.422-.422h1.406c.232 0 .422.19.422.422v1.406c0 .232-.19.422-.422.422zm6.75 0h-1.406a.423.423 0 01-.422-.422v-1.406c0-.232.19-.422.422-.422h1.406c.232 0 .422.19.422.422v1.406c0 .232-.19.422-.422.422z"
        ></path>
      </svg>
    </div>
  );
};

export default CalendarEmptyIcon;
