import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const JoystickTilt: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 18">
        <path
          fill="currentColor"
          d="M7.365 15.989c-.276-.364-.113-.81.34-.81h2.592c.477 0 .621.452.351.81l-1.3 1.726a.423.423 0 01-.69 0L7.365 15.99zM9.004.106c4.47 0 8.104 3.628 8.104 8.105 0 3.54-2.279 6.554-5.443 7.659a1.322 1.322 0 00-.276-1.118 6.962 6.962 0 004.583-6.541 6.973 6.973 0 00-6.968-6.969c-3.849 0-6.975 3.127-6.975 6.969a6.953 6.953 0 004.595 6.541c-.25.301-.358.71-.276 1.118A8.107 8.107 0 01.893 8.21 8.106 8.106 0 019.003.107zm0 2.536a5.57 5.57 0 015.562 5.569 5.566 5.566 0 11-11.13 0 5.572 5.572 0 015.568-5.569zM7.39 5.367c-.364 0-.565.25-.565.64v4.376c0 .414.207.678.61.678h1.744c1.3 0 2.097-.697 2.097-1.827 0-.766-.458-1.4-1.168-1.632l.867-1.338a.69.69 0 00.125-.401c0-.295-.22-.496-.527-.496-.257 0-.395.106-.577.395L8.985 7.413H7.968V6.007c0-.395-.207-.64-.578-.64zm.578 2.888h1.048c.666 0 1.092.345 1.092.954 0 .621-.433.98-1.073.98H7.968V8.254z"
        ></path>
        <path
          fill="#5D697E"
          fillRule="evenodd"
          d="M7.39 5.367c-.364 0-.565.25-.565.64v4.376c0 .414.207.678.61.678h1.744c1.3 0 2.097-.697 2.097-1.827 0-.766-.458-1.4-1.168-1.632l.867-1.338a.69.69 0 00.125-.401c0-.295-.22-.496-.527-.496-.257 0-.395.106-.577.395L8.985 7.413H7.968V6.007c0-.395-.207-.64-.578-.64zm1.626 2.888H7.968v1.933h1.067c.64 0 1.073-.358 1.073-.98 0-.608-.426-.953-1.092-.953z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M7.022 10.473V6h3.956v4.473h-.818V6.498l.192.205H7.649l.191-.205v3.975h-.818z"
        ></path>
      </svg>
    </div>
  );
};

export default JoystickTilt;
