import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const SearchInTextIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 19 15">
        <path
          fill="currentColor"
          d="M5.067 12.116c-.635 0-.984-.335-.984-.998v-.3c.718.334 1.51.519 2.352.519a5.411 5.411 0 003.062-.943l3.486 3.493c.205.198.458.287.725.287.581 0 1.005-.438 1.005-1.012a.996.996 0 00-.287-.71l-3.466-3.48a5.44 5.44 0 001.039-3.2 5.537 5.537 0 00-1.45-3.739h6.064c.63 0 .985.328.985.991v8.094c0 .663-.356.998-.985.998h-1.47c.274.39.383.861.308 1.34h1.244c1.477 0 2.242-.766 2.242-2.222V2.915c0-1.463-.765-2.222-2.242-2.222h-7.99a5.606 5.606 0 00-2.27-.485A5.57 5.57 0 00.87 5.772c0 1.662.725 3.145 1.873 4.164v1.298c0 1.463.773 2.222 2.242 2.222h6.447l-1.347-1.34H5.067zM2.32 5.772a4.115 4.115 0 118.23 0 4.115 4.115 0 01-8.23 0zm2.065-.396h4.095c.28 0 .499-.232.499-.506a.493.493 0 00-.5-.499H4.385a.492.492 0 00-.492.5c0 .273.212.505.492.505zm0 1.798h2.96a.496.496 0 00.499-.492.503.503 0 00-.5-.506h-2.96a.493.493 0 00-.491.506c0 .273.212.492.492.492z"
        ></path>
      </svg>
    </div>
  );
};

export default SearchInTextIcon;
