import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const FolderPlusIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 25 17">
        <path
          fill="currentColor"
          d="M20.132 9.138c2.46 0 4.526-2.057 4.526-4.527 0-2.487-2.048-4.526-4.526-4.526-2.479 0-4.527 2.048-4.527 4.526 0 2.488 2.048 4.527 4.527 4.527zM3.81 16.767h14.58c1.697 0 2.681-.985 2.681-2.866v-3.594c-.474.105-1.186.114-1.749 0v3.445c0 .844-.448 1.266-1.257 1.266H3.925c-.809 0-1.257-.422-1.257-1.275v-6.67h12.287a5.127 5.127 0 01-.518-1.539H2.668V3.337c0-.8.448-1.222 1.23-1.222h1.67c.624 0 .994.15 1.495.554l.492.413c.615.492 1.09.668 2.012.668h4.878a4.91 4.91 0 01.51-1.758h-4.922c-.633 0-1.01-.14-1.503-.545l-.492-.404C7.414.533 6.957.366 6.034.366H3.547C1.895.366.919 1.333.919 3.188V13.9c0 1.89.993 2.866 2.892 2.866zM17.258 4.61c0-.369.246-.606.606-.606h1.661V2.344c0-.352.238-.598.607-.598.369-.009.606.237.606.598v1.66H22.4c.36 0 .598.238.598.607 0 .37-.237.607-.598.607h-1.66v1.66c0 .361-.238.607-.607.607-.37 0-.607-.246-.607-.606V5.218h-1.66c-.361 0-.607-.238-.607-.607z"
        ></path>
      </svg>
    </div>
  );
};

export default FolderPlusIcon;
