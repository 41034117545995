import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { HelpCircleIcon, LogOutIcon } from '@icons';
import { TooltipWrapper } from '@ui';

import Avatar from '@/components/ui/avatar';
import { UserPermissions } from '@/store/types/auth';
import { logoutSession } from '@/utils/auth';

type AuthMenuProps = {
  auth: UserPermissions['user'];
};

const AuthMenuBlock: FC<AuthMenuProps> = (props) => {
  const {
    auth: { name },
  } = props;
  function handlerLogout() {
    logoutSession();
  }

  return (
    <ul className="inline-flex divide-x">
      <li className="px-[22px] items-center inline-flex">
        <NavLink
          target={'_blank'}
          replace
          to="https://ansp.cloudpbx.rt.ru/support/Speech_analytics_manual_2_0.pdf"
          className="inline-flex hover:opacity-80 "
        >
          <HelpCircleIcon size={20} className="mr-[4px]" /> Помощь
        </NavLink>
      </li>
      <li className="px-[22px] inline-flex items-center">
        <Avatar alt={name} size={30} classname="mr-[8px] text-speech-analitics" />
        <div className="block max-w-[150px] overflow-hidden truncate">
          <NavLink to="/" className="mr-[8px] text-sm flex self-center">
            <TooltipWrapper truncate content={name} id={'username'}>
              {name}
            </TooltipWrapper>
          </NavLink>
        </div>
        <NavLink to="/authorization" className="hover:opacity-80" onClick={handlerLogout}>
          <LogOutIcon size={16} />
        </NavLink>
      </li>
    </ul>
  );
};

export default AuthMenuBlock;
