import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { Mutex } from 'async-mutex';
import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { toast } from 'react-toastify';

import authStorage from '@/utils/auth';
import { AuthResponse } from '@/interfaces/auth.type';

import { logout } from '@/store/reducers/auth.slice';

import navigationRoutes from '@/utils/navigation-routes';
import serverRoutes from '@/utils/server-routes';
import { AUTH_KEY } from '@/utils/constants';
import { config } from '@/utils/config';

const mutex = new Mutex();

type StorageDataType = {
  detail: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
};

const headerTokenMutation = (tokenType: 'access' | 'refresh', headers: Headers) => {
  const localState = localStorage.getItem(AUTH_KEY);
  if (localState !== null) {
    const localstorageData = JSON.parse(localState) as StorageDataType;
    const token =
      tokenType === 'access' ? localstorageData['access_token'] : localstorageData['refresh_token'];
    if (token) {
      try {
        const tokenValue = tokenType === 'access' ? token : `bearer ${token}`;
        headers.set('Authorization', tokenValue);
      } catch (error) {
        console.error('Token error', error);
      }
    }
  }
  return headers;
};
const baseQueryWithRefreshToken = fetchBaseQuery({
  baseUrl: config.backendUrl,
  prepareHeaders: (headers) => headerTokenMutation('refresh', headers),
});
const baseQueryAccessToken = fetchBaseQuery({
  baseUrl: config.backendUrl,
  prepareHeaders: (headers) => headerTokenMutation('access', headers),
});

const ignoreRoutePaths = [serverRoutes.auth, serverRoutes.hash];

export const uriParamsDecoder = (params: { [key: string]: string } | string) => {
  if (typeof params === 'string') return encodeURI(params).split(' ').join('');
  return Object.entries(params).reduce((paramsStack, [paramKey, paramValue]) => {
    paramsStack[paramKey] = decodeURI(String(paramValue)).split('').join('');
    return paramsStack;
  }, {});
};

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQueryAccessToken(args, api, extraOptions);
  if (result?.meta?.response?.status === 500) {
    toast.error('Ошибка на сервере');
    return { ...result, data: undefined };
  }
  if (result?.meta?.response?.status === 405) {
    return { ...result, data: undefined };
  }
  if (result?.meta?.response?.status === 403) {
    toast.error('Нет доступа');
    return { ...result, meta: { ...result.meta, request: {}, response: {} }, data: undefined };
  }
  if (
    result?.meta?.response?.status === 401 &&
    ignoreRoutePaths.includes(String(result?.meta?.request.url))
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQueryWithRefreshToken(
          { url: serverRoutes.refresh_token },
          api,
          extraOptions,
        );
        if (refreshResult?.meta?.response?.status === 401) {
          await authStorage.logOut();
          api.dispatch(logout());
          if (window.location.pathname !== navigationRoutes.login) {
            window.location.href = navigationRoutes.login;
          }
        } else {
          const data = refreshResult.data as AuthResponse;
          if (data.refresh_token && data.access_token) {
            await authStorage.signIn(data);
          }
          result = await baseQueryAccessToken(args, api, extraOptions);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQueryAccessToken(args, api, extraOptions);
    }
  }
  return result;
};
