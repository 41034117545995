import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '../base-query';

import serverRoutes from '@/utils/server-routes';
import { UserPermissions } from '@/store/types/auth';
import { logout, setUser } from '@/store/reducers/auth.slice';

export const userInfoApi = createApi({
  reducerPath: 'userInfoApi',
  tagTypes: ['USERINFO'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getUserInfo: build.query<UserPermissions, void>({
      query: () => ({
        url: serverRoutes.authMe,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          dispatch(logout());
          console.error(error);
        }
      },
      providesTags: ['USERINFO'],
    }),
  }),
});

export const { useGetUserInfoQuery } = userInfoApi;
