import cn from 'classnames';

export const wrapperLabelClassName = ({ variant }: { variant: string }) =>
  cn(
    'cursor-pointer',
    variant,
    'uppercase',
    'truncate',
    'font-[600]',
    'text-[13px]',
    'rounded-[4px]',
    'py-[2px]',
    'flex',
    'items-center',
    'px-[20px]',
    'select-none',
    'h-full',
  );

export const dropClassName = ({
  show,
  isLeft,
  isRight,
}: {
  show: boolean;
  isLeft?: boolean;
  isRight?: boolean;
}) =>
  cn(
    'absolute',
    'bg-white',
    show ? 'visible scale-x-100' : 'invisible scale-x-75',
    isLeft
      ? 'top-[-16px] mr-[5px] top-[calc(100%-30px)] right-full'
      : isRight
        ? 'top-[-16px] ml-[5px] top-[calc(100%-30px)] left-full'
        : 'top-full right-0 w-fit mt-[10px] ',
    'duration-150',
    'rounded-defaultR',
    'shadow-hard',
    'p-[5px]',
    'z-[20]',
  );

export const dropListUlClassName = ({ isLeft, isRight }: { isLeft?: boolean; isRight?: boolean }) =>
  cn(
    isLeft ? 'flex-row w-[200px]' : isRight ? 'flex-row w-[200px]' : 'flex-col w-full',
    'overflow-x-auto',
    'scrollHidden',
    'inline-flex',
    'gap-[5px]',
  );

export const triangleDropClassName = ({
  isLeft,
  isRight,
}: {
  isLeft?: boolean;
  isRight?: boolean;
}) =>
  cn(
    isLeft
      ? 'right-[-10px] top-[2px]'
      : isRight
        ? 'left-[-10px] top-[2px] -rotate-180'
        : 'right-[20px] top-[-18px] -rotate-90',
    'text-white',
    'absolute',
  );
