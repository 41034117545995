import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const BookIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 19">
        <path
          fill="currentColor"
          d="M4.568 17.905h10.75a.66.66 0 00.663-.664.69.69 0 00-.464-.639c-1.046-.415-1.262-1.768-.233-2.806.316-.315.697-.722.697-1.552v-9.52c0-1.727-.854-2.607-2.573-2.607H4.593C2.875.117 2.02.988 2.02 2.723V15.34c0 1.71.863 2.565 2.548 2.565zm8.774-16.452c.863 0 1.303.465 1.303 1.295v9.313c0 .449-.257.706-.722.706h-8.5V1.453h7.919zM3.356 13.107V2.748c0-.722.332-1.162.98-1.27v11.306a2.28 2.28 0 00-.98.323zm3.553-7.67h6.225c.3 0 .54-.249.54-.547 0-.3-.24-.54-.54-.54H6.91c-.3 0-.54.24-.54.54 0 .298.24.548.54.548zm0 2.283h4.723c.299 0 .54-.249.54-.548 0-.298-.241-.54-.54-.54H6.909c-.3 0-.54.242-.54.54 0 .3.24.548.54.548zM4.643 16.57c-.839 0-1.287-.448-1.287-1.237 0-.747.531-1.229 1.361-1.229h8.517c.108 0 .207-.008.29-.024-.39.863-.323 1.776.117 2.49H4.643z"
        ></path>
      </svg>
    </div>
  );
};

export default BookIcon;
