import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const Meta: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 57 57">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          d="M19.601 38h2.49V25h.098l5.298 13h2.026l5.31-13h.086v13h2.49V20.385h-3.15l-5.712 14.063h-.074l-5.7-14.063H19.6V38z"
        ></path>
      </svg>
    </div>
  );
};

export default Meta;
