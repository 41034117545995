import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const BrokenRobotIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        width={size}
        viewBox="0 0 700 700"
      >
        <defs stroke="currentColor">
          <symbol stroke="currentColor" overflow="visible">
            <path
              fill="currentColor"
              d="M18.766-1.125c-.969.5-1.98.875-3.031 1.125-1.043.258-2.137.39-3.281.39-3.399 0-6.09-.945-8.079-2.843-1.992-1.906-2.984-4.485-2.984-7.735 0-3.257.992-5.835 2.984-7.734 1.989-1.906 4.68-2.86 8.079-2.86 1.144 0 2.238.133 3.28.391 1.052.25 2.063.625 3.032 1.125v4.219c-.98-.656-1.945-1.14-2.89-1.453a9.532 9.532 0 00-3-.469c-1.876 0-3.352.606-4.423 1.813-1.074 1.199-1.609 2.855-1.609 4.968 0 2.106.535 3.762 1.61 4.97C9.523-4.02 11-3.423 12.874-3.423c1.051 0 2.051-.148 3-.453.946-.312 1.91-.8 2.891-1.469z"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              fill="currentColor"
              d="M13.734-11.141a6.765 6.765 0 00-1.297-.438 5.346 5.346 0 00-1.265-.156c-1.262 0-2.231.406-2.907 1.219-.68.805-1.015 1.953-1.015 3.453V0H2.36v-15.312h4.89v2.515c.625-1 1.344-1.726 2.156-2.187.82-.469 1.8-.703 2.937-.703.164 0 .344.011.532.03.195.013.476.04.843.079z"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              fill="currentColor"
              d="M17.641-7.703v1.406H6.188c.125 1.148.54 2.008 1.25 2.578.707.574 1.703.86 2.984.86a11.52 11.52 0 003.157-.454 16.707 16.707 0 003.328-1.39v3.765a20.629 20.629 0 01-3.47.985A18.006 18.006 0 019.97.39C7.196.39 5.04-.313 3.5-1.72 1.97-3.125 1.203-5.098 1.203-7.64c0-2.5.754-4.46 2.266-5.89 1.508-1.438 3.582-2.157 6.219-2.157 2.406 0 4.332.731 5.781 2.188 1.445 1.45 2.172 3.383 2.172 5.797zM12.61-9.328c0-.926-.274-1.672-.813-2.234-.543-.57-1.25-.86-2.125-.86-.949 0-1.719.266-2.312.797s-.965 1.297-1.11 2.297z"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              fill="currentColor"
              d="M9.219-6.89c-1.024 0-1.793.171-2.313.515-.511.344-.765.855-.765 1.531 0 .625.207 1.117.625 1.469.414.344.988.516 1.718.516.926 0 1.704-.328 2.329-.985.632-.664.953-1.492.953-2.484v-.563zm7.469-1.844V0h-4.922v-2.266c-.657.93-1.399 1.606-2.22 2.032-.823.414-1.823.625-3 .625-1.585 0-2.87-.457-3.859-1.375-.992-.926-1.484-2.13-1.484-3.61 0-1.789.613-3.101 1.844-3.937 1.238-.844 3.18-1.266 5.828-1.266h2.89v-.39c0-.77-.308-1.333-.921-1.688-.617-.363-1.57-.547-2.86-.547-1.054 0-2.03.106-2.937.313-.899.21-1.73.523-2.5.937v-3.734c1.039-.25 2.086-.442 3.14-.578a25.68 25.68 0 013.188-.204c2.758 0 4.75.547 5.969 1.641 1.226 1.086 1.844 2.856 1.844 5.313z"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M7.703-19.656v4.344h5.047v3.5H7.703v6.5c0 .71.14 1.187.422 1.437s.836.375 1.672.375h2.515V0H8.125C6.187 0 4.812-.398 4-1.203c-.805-.812-1.203-2.18-1.203-4.11v-6.5H.375v-3.5h2.422v-4.343z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M12.766-13.078v-8.203h4.922v21.28h-4.922v-2.218c-.668.906-1.406 1.57-2.219 1.985S8.79.39 7.72.39c-1.887 0-3.434-.75-4.64-2.25-1.211-1.5-1.813-3.426-1.813-5.782 0-2.363.602-4.297 1.812-5.797 1.207-1.5 2.754-2.25 4.641-2.25 1.063 0 2 .215 2.813.641.82.43 1.566 1.086 2.234 1.969zM9.547-3.156c1.04 0 1.836-.379 2.39-1.14.552-.77.829-1.883.829-3.344 0-1.457-.277-2.567-.828-3.329-.555-.77-1.352-1.156-2.39-1.156-1.044 0-1.84.387-2.391 1.156-.555.762-.829 1.872-.829 3.329 0 1.46.274 2.574.829 3.343.55.762 1.347 1.14 2.39 1.14z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M10.5-3.156c1.05 0 1.852-.38 2.406-1.14.551-.77.828-1.884.828-3.345 0-1.457-.277-2.566-.828-3.328-.554-.77-1.355-1.156-2.406-1.156-1.055 0-1.86.387-2.422 1.156-.555.774-.828 1.883-.828 3.328 0 1.45.273 2.559.828 3.329.563.773 1.367 1.156 2.422 1.156zm-3.25-9.922c.676-.883 1.422-1.54 2.234-1.969.82-.426 1.766-.64 2.829-.64 1.894 0 3.445.75 4.656 2.25 1.207 1.5 1.812 3.433 1.812 5.796 0 2.356-.605 4.282-1.812 5.782C15.758-.36 14.207.39 12.312.39 11.25.39 10.305.18 9.484-.234 8.672-.66 7.926-1.32 7.25-2.22V0H2.36v-21.281h4.89z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M.344-15.312h4.89L9.36-4.921l3.5-10.391h4.89L11.313 1.454c-.648 1.695-1.402 2.883-2.265 3.562-.867.688-2 1.032-3.406 1.032H2.797v-3.22h1.531c.832 0 1.438-.136 1.813-.405.382-.262.68-.73.89-1.407l.14-.421z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M2.36-21.281h4.89v11.594l5.625-5.625h5.688l-7.47 7.031L19.157 0H13.22l-5.97-6.39V0H2.36z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M14.312-14.828v3.719a18.075 18.075 0 00-3.031-.985 12.73 12.73 0 00-2.766-.328c-.93 0-1.62.117-2.078.344-.45.23-.672.59-.672 1.078 0 .387.172.684.516.89.344.212.957.368 1.844.47l.859.125c2.508.324 4.195.851 5.063 1.578.863.73 1.296 1.87 1.296 3.422 0 1.636-.605 2.867-1.812 3.687-1.2.813-2.992 1.219-5.375 1.219C7.133.39 6.082.313 5 .156a25.26 25.26 0 01-3.313-.718v-3.72c.969.481 1.961.84 2.985 1.079 1.03.23 2.078.344 3.14.344.957 0 1.676-.13 2.156-.39.477-.27.72-.665.72-1.188 0-.438-.169-.758-.5-.97-.337-.218-.997-.382-1.985-.5l-.86-.109c-2.18-.269-3.703-.773-4.578-1.515-.875-.738-1.312-1.86-1.312-3.36 0-1.625.55-2.828 1.656-3.609 1.113-.789 2.82-1.187 5.125-1.187.895 0 1.836.074 2.828.218 1 .137 2.082.352 3.25.641z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M17.75-9.328V0h-4.922v-7.11c0-1.343-.031-2.265-.094-2.765s-.168-.867-.312-1.11a1.994 1.994 0 00-.781-.734 2.295 2.295 0 00-1.11-.265c-1.023 0-1.824.398-2.406 1.187-.586.781-.875 1.871-.875 3.266v7.53H2.36v-21.28h4.89v8.203c.738-.883 1.52-1.54 2.344-1.969.832-.426 1.75-.64 2.75-.64 1.77 0 3.113.546 4.031 1.64.914 1.086 1.375 2.656 1.375 4.719z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M12.422-21.281v3.219H9.719c-.688 0-1.172.125-1.453.375-.274.25-.406.687-.406 1.312v1.063h4.187v3.5H7.86V0H2.969v-11.812H.531v-3.5H2.97v-1.063c0-1.664.46-2.898 1.39-3.703.926-.8 2.368-1.203 4.329-1.203z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M9.64-12.188c-1.085 0-1.914.39-2.484 1.172-.574.781-.86 1.906-.86 3.375s.286 2.594.86 3.375c.57.773 1.399 1.156 2.485 1.156 1.062 0 1.875-.383 2.437-1.156.57-.781.86-1.906.86-3.375s-.29-2.594-.86-3.375c-.562-.781-1.375-1.172-2.437-1.172zm0-3.5c2.633 0 4.692.715 6.172 2.14 1.477 1.419 2.22 3.387 2.22 5.907 0 2.512-.743 4.48-2.22 5.906C14.333-.317 12.274.39 9.642.39c-2.649 0-4.715-.707-6.203-2.125-1.493-1.426-2.235-3.394-2.235-5.906 0-2.52.742-4.488 2.235-5.906 1.488-1.426 3.554-2.141 6.203-2.141z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M16.547-12.766c.613-.945 1.348-1.672 2.203-2.172.852-.5 1.79-.75 2.813-.75 1.757 0 3.097.547 4.015 1.64.926 1.087 1.39 2.657 1.39 4.72V0h-4.921v-8.345c.008-.132.016-.32.016-.562 0-1.082-.165-1.863-.485-2.343-.312-.489-.824-.735-1.531-.735-.93 0-1.648.387-2.156 1.156-.512.762-.774 1.868-.782 3.313v7.515h-4.921v-7.984c0-1.695-.149-2.785-.438-3.266-.293-.488-.812-.734-1.562-.734-.938 0-1.665.387-2.172 1.156-.512.762-.766 1.86-.766 3.297V0H2.328v-15.312H7.25v2.235c.602-.864 1.29-1.516 2.063-1.953a5.186 5.186 0 012.578-.657c1.062 0 2 .258 2.812.766.813.512 1.426 1.23 1.844 2.156z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M2.578-20.406h5.875l7.422 14v-14h4.984V0h-5.875L7.563-14V0H2.578z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M2.188-5.969v-9.344h4.921v1.532c0 .836-.007 1.875-.015 3.125-.012 1.25-.016 2.086-.016 2.5 0 1.242.031 2.132.094 2.672.07.542.18.933.328 1.171.207.325.473.575.797.75.32.168.691.25 1.11.25 1.019 0 1.82-.39 2.406-1.171.582-.782.875-1.868.875-3.266v-7.563h4.89V0h-4.89v-2.218c-.743.898-1.524 1.558-2.344 1.984C9.519.179 8.609.39 7.609.39c-1.761 0-3.105-.54-4.03-1.625-.93-1.082-1.391-2.66-1.391-4.734z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M17.75-9.328V0h-4.922v-7.14c0-1.32-.031-2.235-.094-2.735s-.168-.867-.312-1.11a1.994 1.994 0 00-.781-.734 2.295 2.295 0 00-1.11-.265c-1.023 0-1.824.398-2.406 1.187-.586.781-.875 1.871-.875 3.266v7.53H2.36v-15.311h4.89v2.234c.738-.883 1.52-1.539 2.344-1.969.832-.425 1.75-.64 2.75-.64 1.77 0 3.113.547 4.031 1.64.914 1.086 1.375 2.657 1.375 4.72z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M2.578-20.406h8.735c2.593 0 4.581.578 5.968 1.734 1.395 1.149 2.094 2.79 2.094 4.922 0 2.137-.7 3.782-2.094 4.938-1.386 1.156-3.375 1.734-5.968 1.734H7.828V0h-5.25zm5.25 3.813v5.703h2.922c1.02 0 1.805-.25 2.36-.75.562-.5.843-1.203.843-2.11 0-.914-.281-1.617-.844-2.11-.554-.487-1.34-.734-2.359-.734z"></path>
          </symbol>
          <symbol stroke="currentColor" overflow="visible">
            <path d="M2.36-15.312h4.89V-.281c0 2.05-.496 3.617-1.484 4.703-.98 1.082-2.407 1.625-4.282 1.625H-.937V2.828h.859c.926 0 1.562-.21 1.906-.625.352-.418.531-1.246.531-2.484zm0-5.969h4.89v4H2.36z"></path>
          </symbol>
          <symbol overflow="visible">
            <path d="M14.719-14.828v3.984a7.612 7.612 0 00-2-1.015 6.629 6.629 0 00-2.078-.328c-1.367 0-2.434.402-3.203 1.203-.762.793-1.14 1.906-1.14 3.344 0 1.43.378 2.543 1.14 3.343.77.793 1.836 1.188 3.203 1.188.758 0 1.484-.11 2.172-.328a6.412 6.412 0 001.906-1.016v4c-.762.281-1.54.488-2.328.625a13.04 13.04 0 01-2.375.219C7.254.39 5.094-.316 3.53-1.734 1.977-3.148 1.203-5.117 1.203-7.64c0-2.531.774-4.504 2.328-5.922 1.563-1.414 3.723-2.125 6.485-2.125.8 0 1.594.074 2.375.219.781.136 1.555.351 2.328.64z"></path>
          </symbol>
        </defs>
        <path d="M231.13 326.64a6.217 6.217 0 004.414 1.832c1.598 0 3.191-.61 4.414-1.832l22.641-22.641 22.641 22.641c1.223 1.223 2.817 1.832 4.414 1.832s3.192-.61 4.414-1.832a6.231 6.231 0 000-8.824l-22.64-22.641 22.64-22.641a6.231 6.231 0 000-8.824 6.231 6.231 0 00-8.824 0l-22.64 22.64-22.642-22.64a6.231 6.231 0 00-8.824 0 6.231 6.231 0 000 8.824l22.641 22.64-22.641 22.642a6.224 6.224 0 00-.008 8.824zM437.41 257.92c-20.539 0-37.246 16.711-37.246 37.246 0 20.535 16.711 37.258 37.246 37.258 20.539 0 37.25-16.715 37.25-37.258 0-20.539-16.711-37.246-37.25-37.246zm0 62.438c-13.887 0-25.176-11.301-25.176-25.184 0-13.887 11.289-25.176 25.176-25.176s25.184 11.289 25.184 25.176c0 13.883-11.301 25.184-25.184 25.184zM433.96 415.93l-29.734 29.734-27.988-27.988-31.488 31.488-26.242-26.242-27.988 27.988-27.988-27.988-31.703 31.703c-2.05 2.05-2.05 5.371 0 7.422s5.371 2.05 7.422 0l24.277-24.277 27.988 27.988 27.988-27.988 26.242 26.242 31.488-31.488 27.988 27.988 29.734-29.734 27.777 27.777a5.246 5.246 0 007.422 0 5.246 5.246 0 000-7.422z"></path>
        <path d="M587.34 220.28h-14.043v-6.64c0-55.345-44.902-100.25-100.25-100.25h-32.531V97.591c0-19.848-19.191-35.984-42.789-35.984h-38.684V36.206h60.961C422.809 49.96 435 60.347 449.57 60.347c16.637 0 30.18-13.535 30.18-30.18 0-16.64-13.539-30.172-30.18-30.172-12.344 0-22.949 7.465-27.621 18.105h-81.012v43.512h-38.684c-23.598 0-42.789 16.141-42.789 35.984v15.797h-32.531c-55.344 0-100.25 44.902-100.25 100.25v6.641l-14.039.004c-19.848 0-35.984 19.19-35.984 42.789v95.473c0 23.598 16.141 42.789 35.984 42.789h14.043v58.418c0 55.344 44.902 100.25 100.25 100.25h246.11c55.344 0 100.25-44.902 100.25-100.25v-58.422h14.043c19.848 0 35.984-19.191 35.984-42.79l.004-95.476c-.004-23.598-16.145-42.79-35.992-42.79zM449.57 12.07c9.984 0 18.105 8.121 18.105 18.105S459.554 48.28 449.57 48.28s-18.105-8.121-18.105-18.105 8.121-18.105 18.105-18.105zm-172 85.535c0-11.469 11.043-20.758 24.684-20.758h95.473c13.641 0 24.684 9.29 24.684 20.758v15.797h-144.84zm-164.93 285.63c-11.469 0-20.758-11.043-20.758-24.684l-.004-95.477c0-13.641 9.29-24.684 20.758-24.684h14.043v144.84zm442.54 76.523c0 45.266-36.875 82.141-82.141 82.141h-246.11c-45.266 0-82.141-36.875-82.141-82.141v-246.11c0-45.266 36.875-82.141 82.141-82.141h246.11c45.266 0 82.141 36.875 82.141 82.141v246.11zm52.91-101.21c0 13.641-9.29 24.684-20.758 24.684h-14.043l-.004-144.84h14.043c11.47 0 20.758 11.043 20.758 24.684z"></path>
      </svg>
    </div>
  );
};

export default BrokenRobotIcon;
