import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const CharacterBook: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 12 15">
        <path
          fill="currentColor"
          d="M2.635 14.558h8.167c.333 0 .59-.257.59-.59 0-.282-.194-.49-.433-.602-.772-.346-.923-1.413-.138-2.16.32-.282.565-.596.565-1.23v-6.95c0-1.356-.697-2.066-2.047-2.066H2.647C1.304.96.601 1.664.601 3.026v9.486c0 1.343.703 2.046 2.034 2.046zm-.082-4.137a.384.384 0 01-.383-.383v-7.64c0-.213.176-.383.383-.383.207 0 .383.17.383.383v7.64a.384.384 0 01-.383.383zm2.53-1.77c-.314 0-.502-.182-.502-.471 0-.094.019-.195.069-.345l1.419-3.88c.138-.396.395-.59.778-.59.396 0 .66.194.797.59l1.42 3.88c.05.15.068.25.068.345 0 .283-.194.47-.502.47-.257 0-.427-.125-.521-.445l-.333-.992h-1.84l-.338.992c-.088.32-.264.446-.515.446zm1.08-2.216H7.55l-.665-2.022h-.057l-.665 2.022zm-3.359 6.943c-.672 0-1.023-.383-1.023-.973 0-.571.402-.967 1.067-.967h6.265c.07 0 .139-.006.201-.019-.314.666-.276 1.388.063 1.96H2.804z"
        ></path>
      </svg>
    </div>
  );
};

export default CharacterBook;
