export type RoutePathKey =
  | 'main'
  | 'editor'
  | 'login'
  | 'error'
  | 'model'
  | 'newEditor'
  | 'hashAuth';
const routePrefix: string = '';
const navigationRoutes: Record<RoutePathKey, string> = {
  main: `${routePrefix}/`,
  model: `${routePrefix}/model`,
  editor: `${routePrefix}/editor`,
  newEditor: `${routePrefix}/editor/new`,
  login: `${routePrefix}/authorization`,
  hashAuth: `${routePrefix}/auth`,
  error: `*`,
};
export default navigationRoutes;
