import React, { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Loading } from '@ui';

import { BreadcrumbsItem } from './blocks/breadcrumbs/breadcrumbs.type';
import HeaderBlock from './blocks/header.block';
import FooterBlock from './blocks/footer.block';
import LayoutContainerBlock from './blocks/layout-container.block';
import MenuBar from './blocks/menu-bar/menu-bar';

import faviconDefault from '@/imgs/favicon/faviconDefault.png';
import faviconRtk from '@/imgs/favicon/faviconRtk.png';
import { config } from '@/utils/config';
import { useGetUserInfoQuery } from '@/store/api/user-info.api';
import navigationRoutes from '@/utils/navigation-routes';

type LayoutWithAuthProps = {
  breadcrumbs?: Array<BreadcrumbsItem>;
  notAllowAuthPath?: Array<string>;
};

const LayoutOutlet: FC<LayoutWithAuthProps> = (props) => {
  const { notAllowAuthPath } = props;
  const location = useLocation();
  const navigation = useNavigate();
  const isNotRedirectRule = notAllowAuthPath?.includes(location.pathname);
  const { data, isFetching, isLoading } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: isNotRedirectRule,
  });
  const { t } = useTranslation();
  const isLoadedPage = isLoading && isFetching;
  if (isLoadedPage) return <Loading />;
  if (!data?.user) {
    !isNotRedirectRule && navigation(navigationRoutes.hashAuth);
    return <Outlet />;
  }
  return (
    <div className="bg-basic_app_bg  border-l-[3px] border-l-speech_analitics">
      <HeaderBlock />
      <div className="flex flex-row">
        <MenuBar />
        <div className="flex flex-col flex-1 relative overflow-hidden">
          <LayoutContainerBlock>
            <Outlet />
          </LayoutContainerBlock>
          <FooterBlock />
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={config.appTheme === 'rtk' ? faviconRtk : faviconDefault}
              sizes="16x16"
            />
            <title>{t('common.app_name')}</title>
          </Helmet>
        </div>
      </div>
    </div>
  );
};

export default LayoutOutlet;
