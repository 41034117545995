import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const ChartIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        fill="none"
        viewBox="0 0 23 20"
      >
        <path
          fill="currentColor"
          d="M2.117 17.777c0 .449.299.747.747.747h16.718c.523 0 .938-.39.938-.921 0-.54-.415-.93-.939-.93H4.191c-.157 0-.223-.058-.223-.224v-4.524l3.37-1.95c.29.257.672.415 1.096.415.348 0 .68-.117.946-.3l1.552 1.304a1.665 1.665 0 103.254.498c0-.066-.008-.133-.008-.2l3.685-2.207c.266.158.573.257.897.257.921 0 1.668-.747 1.668-1.668a1.665 1.665 0 10-3.304.282l-3.635 2.183a1.637 1.637 0 00-.971-.315c-.266 0-.515.058-.73.174l-1.71-1.436a1.12 1.12 0 00.024-.24 1.669 1.669 0 00-3.337 0v.032l-2.797 1.619V3.442c0-.523-.39-.938-.922-.938-.54 0-.93.415-.93.938v14.335z"
        ></path>
      </svg>
    </div>
  );
};

export default ChartIcon;
