import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, CircleButton, Input, TooltipWrapper } from '@ui';
import cn from 'classnames';

import { Pagination } from '@/interfaces/common.type';
import { PaginationChangeArgs } from '@/pages/editor/editor.page';
import { uriParamsDecoder } from '@/store/base-query';

type FilterForm = {
  sortDesc: boolean;
  filter: string | undefined;
};

const DEFAULT_FILTER = {
  filter: '',
  sortDesc: false,
};

type FilterBlockProps = {
  onChangePagination: (paginationArgs: PaginationChangeArgs) => void;
};

const FilterBlock: FC<FilterBlockProps> = (props) => {
  const { onChangePagination } = props;
  const {
    register: filterRegister,
    setValue: filterSetValue,
    watch: filterWatch,
  } = useForm<Required<FilterForm>>({
    defaultValues: DEFAULT_FILTER,
  });

  function submitFilterHandler() {
    Object.entries(filterWatch()).forEach(([keyFilter, valueFilter]) => {
      console.log(keyFilter, valueFilter);
      if (valueFilter !== '') {
        onChangePagination({
          paginationType: keyFilter as keyof Pagination,
          paginationValue:
            typeof valueFilter === 'string'
              ? (uriParamsDecoder(valueFilter) as string)
              : valueFilter,
        });
      }
    });
  }
  return (
    <Box className="flex gap-[10px] sticky top-[5px] p-[10px] z-30">
      <div className="flex-1">
        <Input
          {...filterRegister('filter')}
          defaultValue={filterWatch().filter || ''}
          placeholder="Введите слово для поиска"
        />
      </div>
      <div>
        <TooltipWrapper content="Сортировка" id="sortIcon">
          <CircleButton
            size={20}
            icon="SortIcon"
            className={cn(filterWatch().sortDesc ? 'text-action' : 'text-gray')}
            onClick={() => filterSetValue('sortDesc', !filterWatch().sortDesc)}
          />
        </TooltipWrapper>
      </div>
      <div>
        <Button label="Поиск" onClick={submitFilterHandler} />
      </div>
    </Box>
  );
};

export default FilterBlock;
