import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const ColumnDisplayIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg width={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.46875 8.83594C6.65625 8.83594 8.49219 7.00781 8.49219 4.8125C8.49219 2.60938 6.67969 0.789062 4.46875 0.789062C2.26562 0.789062 0.453125 2.60938 0.453125 4.8125C0.453125 7.02344 2.26562 8.83594 4.46875 8.83594ZM10.3438 3.38281H15.8594C16.2422 3.38281 16.5547 3.07812 16.5547 2.69531C16.5547 2.3125 16.2422 2.00781 15.8594 2.00781H10.3438C9.96094 2.00781 9.65625 2.3125 9.65625 2.69531C9.65625 3.07812 9.96094 3.38281 10.3438 3.38281ZM4.46875 7.35938C4.14844 7.35938 3.9375 7.14844 3.9375 6.82812V5.34375H2.46094C2.14062 5.34375 1.92969 5.13281 1.92188 4.8125C1.92188 4.49219 2.14062 4.28125 2.46094 4.28125H3.9375V2.80469C3.9375 2.48438 4.14844 2.27344 4.46875 2.26562C4.79688 2.26562 5 2.48438 5 2.80469V4.28125H6.48438C6.80469 4.28125 7.01562 4.49219 7.01562 4.8125C7.01562 5.13281 6.80469 5.34375 6.48438 5.34375H5V6.82812C5 7.14844 4.79688 7.35938 4.46875 7.35938ZM10.3438 7.47656H15.8594C16.2422 7.47656 16.5547 7.17188 16.5547 6.78906C16.5547 6.40625 16.2422 6.10156 15.8594 6.10156H10.3438C9.96094 6.10156 9.65625 6.40625 9.65625 6.78906C9.65625 7.17188 9.96094 7.47656 10.3438 7.47656ZM1.13281 11.5703H15.8594C16.2422 11.5703 16.5547 11.2656 16.5547 10.8828C16.5547 10.5 16.2422 10.1953 15.8594 10.1953H1.13281C0.75 10.1953 0.445312 10.5 0.445312 10.8828C0.445312 11.2656 0.75 11.5703 1.13281 11.5703ZM1.13281 15.6562H15.8594C16.2422 15.6562 16.5547 15.3594 16.5547 14.9766C16.5547 14.5859 16.2422 14.2891 15.8594 14.2891H1.13281C0.75 14.2891 0.445312 14.5859 0.445312 14.9766C0.445312 15.3594 0.75 15.6562 1.13281 15.6562Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default ColumnDisplayIcon;
