import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const ChevronDownIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
      >
        <path
          fill="currentColor"
          d="M29.719 10.162l-.444-.444a.75.75 0 00-1.063 0L16 21.938 3.781 9.717a.75.75 0 00-1.062 0l-.444.444a.75.75 0 000 1.063l13.188 13.193a.75.75 0 001.062 0l13.188-13.193c.3-.294.3-.77.006-1.063z"
        ></path>
      </svg>
    </div>
  );
};

export default ChevronDownIcon;
