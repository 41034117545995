import React, { ChangeEvent, FC, ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';

const Checkbox: FC<{
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  disabled?: boolean;
}> = forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => {
  const { checked, onChange, touched, disabled, ...rest } = props;
  const checkboxClassName = cn(
    'form-check-input',
    'ring-none',
    'outline-0',
    'appearance-none',
    'h-3',
    'w-3',
    'border',
    'border-gray-300',
    'rounded-sm',
    touched && !checked ? 'bg-action' : 'bg-white',
    'checked:bg-action',
    'checked:border-action',
    'focus:outline-none',
    'transition',
    'duration-200',
    'align-top',
    'bg-no-repeat',
    'bg-center',
    'bg-contain',
    'float-left',
    'cursor-pointer',
  );
  return (
    <div>
      <input
        ref={ref}
        className={checkboxClassName}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        style={
          touched && !checked
            ? {
                backgroundSize: '2px 70%',
                rotate: '90deg',
                backgroundRepeat: 'no-repeat',
                border: 'none',
              }
            : {}
        }
        onChange={(e) => onChange?.(e)}
        {...rest}
      />
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
