import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const ArchiveBox: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 17">
        <path
          fill="currentColor"
          d="M4.175 16.88h9.641c1.82 0 2.813-.966 2.813-2.776V5.596c.826-.22 1.292-.923 1.292-1.899V2.423c0-1.195-.694-1.969-1.898-1.969H1.978C.818.454.07 1.228.07 2.423v1.274c0 .976.466 1.67 1.301 1.899v8.508c0 1.81.993 2.777 2.804 2.777zM2.312 4.059c-.405 0-.58-.185-.58-.59v-.817c0-.404.175-.588.58-.588h13.385c.396 0 .563.184.563.588v.818c0 .404-.167.589-.563.589H2.312zm1.95 11.144c-.738 0-1.142-.395-1.142-1.142V5.666h11.751v8.394c0 .747-.404 1.142-1.134 1.142H4.263zM6.17 9.261h5.66c.422 0 .72-.308.72-.739v-.28c0-.431-.298-.721-.72-.721H6.17c-.422 0-.72.29-.72.72v.281c0 .431.298.739.72.739z"
        ></path>
      </svg>
    </div>
  );
};

export default ArchiveBox;
