import React from 'react';
import { NavLink } from 'react-router-dom';

import AuthMenuBlock from '../blocks/auth-menu.block';

import navigationRoutes from '@/utils/navigation-routes';
import logo from '@/imgs/imagesToBuild';
import { useAppSelector } from '@/store';

const HeaderBlock = () => {
  const { userInfo } = useAppSelector((state) => state.authState);
  const renderAuthMenu = userInfo && <AuthMenuBlock auth={userInfo} />;
  const renderCurrentIndexLink = userInfo ? navigationRoutes.main : navigationRoutes.login;
  return (
    <div className="bg-white flex justify-between px-[26px] text-sm font-[500] shadow-medium items-center border-b-[1px] border-b-b_light h-[67px] w-full">
      <NavLink to={renderCurrentIndexLink} className="inline-flex items-center">
        <div className="text-primary_copy mr-[16px]">
          <img src={logo} alt="LOGO" />
        </div>
      </NavLink>
      <div>{renderAuthMenu}</div>
    </div>
  );
};

export default HeaderBlock;
