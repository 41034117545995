import React from 'react';
import { Route } from 'react-router-dom';

import navigationRoutes from '@/utils/navigation-routes';
import { EditorOutlet, EditorPage, EditorSwitchPage, NewEditorPage } from '@/pages/editor';
import { BreadcrumbsItem } from '@/pages/layout';

export const editorRoute = (
  <Route
    path={navigationRoutes.editor}
    element={<EditorOutlet />}
    handle={{
      crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.editor}`} crumb="editor" />,
    }}
  >
    <Route index element={<EditorPage />} />
    <Route path=":model_id" element={<EditorSwitchPage />} />
    <Route path={navigationRoutes.newEditor} element={<NewEditorPage />} />
  </Route>
);
