import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const AvgPauseDuration: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 57 57">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <g clipPath="url(#clip0_1252_12175)">
          <path
            fill="#2987CC"
            fillRule="evenodd"
            d="M28.403 24.06A19.427 19.427 0 009 43.462a19.018 19.018 0 00.687 5.104.742.742 0 00.716.553h36a.742.742 0 00.717-.553c.46-1.662.69-3.38.687-5.104a19.426 19.426 0 00-19.404-19.404zm-.746 2.985a.746.746 0 111.493 0v1.26a.747.747 0 01-1.493 0v-1.26zM13.24 44.165l-1.254-.008a.746.746 0 110-1.493l1.254.008a.746.746 0 110 1.492zm2.709-7.948a.769.769 0 01-.65.365.753.753 0 01-.373-.097l-1.089-.634a.758.758 0 01-.269-1.023.746.746 0 011.023-.268l1.082.634a.754.754 0 01.276 1.023zm5.299-5.262a.75.75 0 01-1.023-.283l-.62-1.09a.747.747 0 111.3-.739l.619 1.09a.754.754 0 01-.276 1.022zm16.015-1.328l-.627 1.082a.746.746 0 11-1.291-.746l.627-1.082a.745.745 0 111.29.746zm4.276 7.015a.74.74 0 01-.642-.373.751.751 0 01.269-1.023l1.09-.627a.754.754 0 011.022.276A.746.746 0 0143 35.91l-1.09.627a.672.672 0 01-.373.105zm3.284 7.59h-1.254a.746.746 0 110-1.493h1.254a.747.747 0 010 1.492zM29.5 46.075c-.347.147-.72.223-1.097.224a2.856 2.856 0 01-2.604-1.74 2.809 2.809 0 01.866-3.32c.49-.38.693-.44.992-.5v-9.323c.295-.623 1.178-.623 1.493 0v9.323c.109.03.216.068.32.112a2.775 2.775 0 011.538 1.522 2.836 2.836 0 01-1.508 3.702z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#2987CC"
            d="M27.29 41.767a.607.607 0 00-.392.14.445.445 0 00-.163.337v2.384c0 .126.058.248.163.337.104.09.245.14.393.14.148 0 .289-.05.393-.14a.445.445 0 00.163-.337v-2.384a.445.445 0 00-.163-.337.607.607 0 00-.393-.14zm2.226 0c-.148 0-.29.05-.393.14a.445.445 0 00-.163.337v2.384c0 .126.058.248.163.337.104.09.245.14.393.14.148 0 .289-.05.393-.14a.445.445 0 00.163-.337v-2.384a.445.445 0 00-.163-.337.607.607 0 00-.393-.14z"
          ></path>
          <path
            fill="#EEF9FD"
            stroke="#EEF9FD"
            strokeWidth="0.85"
            d="M34.802 15.53a6.399 6.399 0 11-12.798 0 6.399 6.399 0 0112.798 0z"
          ></path>
          <path
            fill="#2987CC"
            d="M28.403 13.414a.705.705 0 011.41 0v2.82a.705.705 0 01-.705.705h-2.82a.705.705 0 110-1.41h2.115v-2.115z"
          ></path>
          <path
            fill="#2987CC"
            stroke="#2987CC"
            strokeWidth="0.85"
            d="M34.323 15.53a5.92 5.92 0 11-11.84 0 5.92 5.92 0 0111.84 0zm-2.03 3.89a5.5 5.5 0 10-7.78-7.78 5.5 5.5 0 007.78 7.78z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default AvgPauseDuration;
