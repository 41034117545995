import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import cn from 'classnames';

import {
  RubberTable,
  CircleButton,
  TooltipWrapper,
  Toggle,
  PageHeader,
  Input,
  EasyModal,
  Button,
  Label,
  Loading,
} from '@ui';

import { useUserModelBulkQuery, useCanCloneModelBulkQuery } from '@/store/api/model.api';

import navigationRoutes from '@/utils/navigation-routes';
import EmptyModelBlock from '@/pages/editor/blocks/empty-model.block';
import {
  useCheckSwitchEditorQuery,
  useSwitchEditorMutation,
  useCheckEditorModelIdQuery,
} from '@/store/api/editor.api';

interface ModelPageTypes {
  model_id: string;
  name: string;
  description: string;
  status: string;
}

const ModelPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [editingModel, setEditingModel] = useState(null);
  const [deleteModal, setDeleteModal] = useState<ModelPageTypes>();
  const { data: checkSwitchEditorData } = useCheckSwitchEditorQuery();
  const {
    data: userModelBulkData,
    isError,
    isLoading,
  } = useUserModelBulkQuery(
    {},
    {
      pollingInterval: 30000,
    },
  );
  const { data: canCloneModelBulkData } = useCanCloneModelBulkQuery();
  const { data: editorModelid } = useCheckEditorModelIdQuery(undefined, { pollingInterval: 10000 });
  const [switchEditor] = useSwitchEditorMutation();

  const handleToggleWrapperClick = () => {
    if (!checkSwitchEditorData) {
      toast.error(t('pages.mainpagetable.error.someModelInEditor'));
    }
  };

  const transformAndCombineData = () => {
    const userModels =
      userModelBulkData?.list?.map((item) => ({
        model_id: item.model_id,
        name: item.name,
        status: item.status,
        description: item.description,
        canClone: false,
      })) || [];

    const cloneableModels =
      canCloneModelBulkData?.map((item) => ({
        model_id: item.id,
        name: item.name,
        description: item.description,
        status: t('pages.mainpagetable.editer.draft'),
        canClone: true,
      })) || [];
    return [...userModels, ...cloneableModels];
  };
  const combinedData = transformAndCombineData();
  const handleToggleChange = async (modelId, isEditing) => {
    if (checkSwitchEditorData) {
      setEditingModel(isEditing ? modelId : null);
      try {
        await switchEditor({ model_id: modelId });
        if (isEditing) {
          navigate(`${navigationRoutes.editor}/${modelId}`);
        }
      } catch (error) {
        /* empty */
      }
    }
  };

  const filteredArr = () => {
    const editorModelStatusMap = new Map(
      editorModelid?.map((editorModel) => [editorModel.model_id, editorModel.editor_status]),
    );

    return combinedData.map((dataItem) => {
      const editorStatus = editorModelStatusMap.get(dataItem.model_id);
      const isEditing = editorStatus !== undefined;
      const toggleDisabled = !checkSwitchEditorData || (isEditing && editorStatus === 'READY');
      const status = isEditing ? editorStatus : dataItem.status;

      function editModelHandler() {
        if (editingModel === dataItem.model_id) {
          setEditModal(true);
          setDeleteModal(dataItem);
        } else {
          toast.error(t('pages.mainpagetable.error.modelInEditor'));
        }
      }

      return {
        ...dataItem,
        nameCol: (
          <div className="flex items-center truncate pr-[5px]">
            <Link
              className="truncate hover:text-action cursor-pointer"
              to={`${navigationRoutes.model}/${dataItem.model_id}`}
            >
              <b>{dataItem.name} </b>
            </Link>
          </div>
        ),
        description: (
          <TooltipWrapper content={dataItem.description} id={dataItem.description}>
            <div className="truncate">{dataItem.description}</div>
          </TooltipWrapper>
        ),
        edit: (
          <div className="flex items-center gap-[8px]">
            <Label title={status} value={status} />
            <div onClick={handleToggleWrapperClick}>
              <Toggle
                onChecked={(toggleStatus) => handleToggleChange(dataItem.model_id, toggleStatus)}
                checked={isEditing}
                disabled={toggleDisabled}
              />
            </div>
          </div>
        ),
        utils: (
          <div className="flex w-full items-center justify-center border-l-[1px] border-[#F1F2F3]">
            {dataItem.canClone && (
              <CircleButton
                hintTitle={t('pages.mainpagetable.actions.copymodel')}
                icon="CopyIcon"
                size={19}
                className={`ml-[10px] text-3color`}
                link={`${navigationRoutes.newEditor}?model=${dataItem.model_id}`}
              />
            )}
            <CircleButton
              hintTitle={t('pages.mainpagetable.actions.deletemodel')}
              icon="TrashIcon"
              size={19}
              className={`ml-[10px] text-basic_red`}
              onClick={() => {
                setDeleteModel(true);
                setDeleteModal(dataItem);
              }}
            />
            <CircleButton
              hintTitle={t('pages.mainpagetable.actions.editmodel')}
              icon="SettingIcon"
              size={19}
              className={cn(
                editingModel !== dataItem.model_id && 'opacity-50 cursor-not-allowed',
                'ml-[10px] text-3color',
              )}
              onClick={editModelHandler}
            />
          </div>
        ),
      };
    });
  };

  const columns = [
    {
      title: t('pages.mainpagetable.modelname'),
      index: 'nameCol',
    },
    {
      title: t('pages.mainpagetable.description'),
      index: 'description',
    },
    {
      title: t('pages.mainpagetable.edit'),
      index: 'edit',
    },
    {
      title: t('pages.mainpagetable.action'),
      index: 'utils',
      maxWidth: 120,
    },
  ];

  if (isLoading) {
    return <Loading text={t('pages.mainpagetable.loaging')} />;
  }
  if (isError || (userModelBulkData && userModelBulkData.list.length === 0)) {
    return <EmptyModelBlock />;
  }
  return (
    <div className="py-[20px] px-[50px] flex flex-col">
      <PageHeader label={t('pages.mainpagetable.pageheader')}>
        <Input placeholder={t('pages.mainpagetable.inputtitle')} />
      </PageHeader>
      <RubberTable dataSource={filteredArr() || []} columns={columns} />
      <EasyModal show={editModal} variant={'small'} onClose={() => setEditModal(false)}>
        <div className="flex flex-col gap-4 mb-[20px]">
          <Input
            defaultValue={deleteModal?.name}
            label={t('pages.mainpagetable.editmodal.modelname')}
          />
          <Input
            defaultValue={deleteModal?.description}
            label={t('pages.mainpagetable.description')}
          />
          <h1>{t('pages.mainpagetable.editmodal.basemodel')}</h1>
          <div className="flex">
            <h1 className="mr-[10px]">{t('pages.mainpagetable.editmodal.currentstate')}</h1>
            <Label title={deleteModal?.status ?? ''} value={deleteModal?.status ?? ''} />
          </div>
          <Button label={t('pages.mainpagetable.editmodal.button')} />
        </div>
      </EasyModal>
      <EasyModal
        variant={'removal'}
        show={deleteModel}
        withoutFooter={true}
        onClose={() => setDeleteModel(false)}
      >
        <div className="px-[50px]">
          {t('pages.mainpagetable.removemodal.confirm')}
          <br />
          {deleteModal?.name}?
        </div>
      </EasyModal>
    </div>
  );
};

export default ModelPage;
