import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const ArrowLeftIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 16 13">
        <path
          fill="currentColor"
          d="M.992 6.352c0 .234.102.46.281.632l5.149 5.149c.187.18.398.265.61.265.491 0 .843-.351.843-.82a.818.818 0 00-.25-.601l-1.75-1.774-2.313-2.11 1.829.11h9.5c.507 0 .867-.351.867-.851 0-.508-.36-.852-.867-.852h-9.5l-1.82.11L5.874 3.5l1.75-1.773a.849.849 0 00.25-.61c0-.469-.352-.812-.844-.812-.21 0-.422.078-.625.273l-5.133 5.14a.865.865 0 00-.28.634z"
        ></path>
      </svg>
    </div>
  );
};

export default ArrowLeftIcon;
