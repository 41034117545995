import React from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs } from './breadcrumbs';

import { config } from '@/utils/config';

const FooterBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[40px] bg-bg_3 w-full">
      <div className="inline-flex w-full items-center h-full justify-between px-[20px]">
        <div className="inline-flex items-center">
          <Breadcrumbs />
        </div>
        {config.appTheme !== 'rtk' && (
          <div className="text-[14px] font-[500px] inline-flex items-center text-2color">
            <div className="mr-[20px]"> {t('pages.layout.footer.version')}</div>
            <div>
              {t('pages.layout.footer.copyright')} {new Date().getFullYear()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterBlock;
