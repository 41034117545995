import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const VolumeMaxIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 22 16"
      >
        <path
          fill="currentColor"
          d="M18.2 14.897c.315.212.74.124.974-.227 1.289-1.926 2.058-4.321 2.058-6.958 0-2.63-.791-5.017-2.058-6.95-.235-.36-.66-.44-.974-.228-.345.22-.396.645-.154 1.026 1.12 1.699 1.816 3.815 1.816 6.152 0 2.336-.695 4.468-1.816 6.16-.242.38-.19.805.154 1.025zM2.387 10.832h2.072a.22.22 0 01.162.059l3.222 2.878c.381.344.674.498 1.055.498.542 0 .945-.395.945-.937V2.146c0-.542-.403-.982-.952-.982-.381 0-.638.169-1.048.535L4.621 4.562a.22.22 0 01-.162.06H2.387C1.31 4.621.768 5.184.768 6.32v2.813c0 1.135.542 1.699 1.619 1.699zm12.868 2.087c.33.205.733.132.96-.205.952-1.347 1.509-3.15 1.509-5.002 0-1.853-.55-3.67-1.51-5.003-.226-.336-.629-.417-.959-.205-.344.22-.395.645-.139 1.026.784 1.142 1.23 2.63 1.23 4.182 0 1.545-.46 3.032-1.23 4.182-.249.38-.205.806.14 1.025zM2.511 9.543c-.249 0-.366-.117-.366-.366v-2.9c0-.25.117-.367.366-.367h2.285a.7.7 0 00.52-.19l2.93-2.674c.037-.029.073-.058.117-.058.059 0 .103.044.103.11v9.243c0 .066-.044.11-.095.11-.044 0-.081-.022-.125-.059l-2.93-2.659a.72.72 0 00-.52-.19H2.511zm9.822 1.42a.678.678 0 00.938-.19c.585-.783.937-1.904.937-3.061s-.352-2.27-.938-3.069a.668.668 0 00-.937-.176c-.352.235-.418.66-.14 1.07.411.578.638 1.355.638 2.175 0 .813-.234 1.59-.637 2.175-.279.418-.213.835.139 1.077z"
        ></path>
      </svg>
    </div>
  );
};

export default VolumeMaxIcon;
