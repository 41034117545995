import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/store/base-query';
import serverRoutes from '@/utils/server-routes';
import { PhonemizerResponse, PhonemizerWordsTranscription } from '@/interfaces/phonemizer.type';

const phonemizerApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'phonemizerApi',
  tagTypes: ['PHONEMIZER'],
  endpoints: (build) => ({
    checkPhonemizer: build.mutation<PhonemizerWordsTranscription, PhonemizerResponse>({
      query: (body) => ({
        url: serverRoutes.phonemizer,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useCheckPhonemizerMutation } = phonemizerApi;
export default phonemizerApi;
