import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import navigationRoutes from '@/utils/navigation-routes';
import Layout, { BreadcrumbsItem } from '@/pages/layout';
import { modelRoute } from '@/routes/model.route';
import { ModelPage } from '@/pages/model';
import { editorRoute } from '@/routes/editor-route';
import { FallbackPage, NotFoundPage } from '@/pages/system';
import { HashAuthPage } from '@/pages/authorization';

export const rootRoute = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={navigationRoutes.main}
      element={<Layout notAllowAuthPath={[navigationRoutes.hashAuth]} />}
      errorElement={<FallbackPage />}
    >
      <Route
        index
        element={<ModelPage />}
        handle={{
          crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.login}`} crumb="model" />,
        }}
      />
      {/* model routes */}
      {modelRoute}
      {/* editor routes */}
      {editorRoute}
      <Route path={navigationRoutes.hashAuth} element={<HashAuthPage />} />
      <Route path={navigationRoutes.error} element={<NotFoundPage />} />
    </Route>,
  ),
);
