import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const ArchiveBoxWithPlus: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="3 0 20 28">
        <path
          fill="currentColor"
          d="M9.175 21.88h9.641c1.82 0 2.813-.966 2.813-2.776v-8.508c.826-.22 1.292-.923 1.292-1.899V7.423c0-1.195-.694-1.969-1.898-1.969H6.978c-1.16 0-1.908.774-1.908 1.969v1.274c0 .976.466 1.67 1.301 1.899v8.508c0 1.81.993 2.777 2.804 2.777zM7.312 9.059c-.405 0-.58-.185-.58-.59v-.817c0-.404.175-.588.58-.588h13.385c.396 0 .563.184.563.588v.818c0 .404-.167.589-.563.589H7.312zm1.95 11.144c-.738 0-1.142-.395-1.142-1.142v-8.394h11.751v8.394c0 .747-.404 1.142-1.134 1.142H9.263zm1.908-5.941h5.66c.422 0 .72-.308.72-.738v-.282c0-.43-.298-.72-.72-.72h-5.66c-.422 0-.72.29-.72.72v.282c0 .43.298.738.72.738z"
        ></path>
        <mask
          id="path-2-outside-1_484_8754"
          width="10"
          height="10"
          x="2"
          y="16"
          fill="#000"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M2 16H12V26H2z"></path>
          <path d="M6.998 25C9.19 25 11 23.19 11 21s-1.813-4-4.006-4C4.806 17 3 18.81 3 21s1.81 4 3.998 4zm0-.797A3.187 3.187 0 013.804 21c0-1.776 1.42-3.2 3.19-3.2A3.196 3.196 0 0110.2 21a3.191 3.191 0 01-3.202 3.203zm-1.427-2.835h1.06v1.063c0 .213.15.364.36.364.216 0 .37-.15.37-.364v-1.064h1.064c.213 0 .367-.15.367-.363a.353.353 0 00-.367-.368H7.362v-1.063a.354.354 0 00-.372-.368c-.208 0-.36.15-.36.368v1.063H5.572a.354.354 0 00-.37.368c0 .213.154.363.37.363z"></path>
          <path
            fillRule="evenodd"
            d="M6.998 24.203A3.187 3.187 0 013.804 21c0-1.776 1.42-3.2 3.19-3.2A3.196 3.196 0 0110.2 21a3.191 3.191 0 01-3.202 3.203zm-.367-2.835H5.57c-.216 0-.37-.151-.37-.364s.15-.368.37-.368h1.06v-1.063c0-.217.15-.368.36-.368.216 0 .37.15.37.368v1.063h1.064c.216 0 .367.155.367.368a.352.352 0 01-.367.363H7.362v1.064c0 .213-.155.364-.372.364a.348.348 0 01-.36-.364v-1.064z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#5D697E"
          d="M6.998 25C9.19 25 11 23.19 11 21s-1.813-4-4.006-4C4.806 17 3 18.81 3 21s1.81 4 3.998 4zm0-.797A3.187 3.187 0 013.804 21c0-1.776 1.42-3.2 3.19-3.2A3.196 3.196 0 0110.2 21a3.191 3.191 0 01-3.202 3.203zm-1.427-2.835h1.06v1.063c0 .213.15.364.36.364.216 0 .37-.15.37-.364v-1.064h1.064c.213 0 .367-.15.367-.363a.353.353 0 00-.367-.368H7.362v-1.063a.354.354 0 00-.372-.368c-.208 0-.36.15-.36.368v1.063H5.572a.354.354 0 00-.37.368c0 .213.154.363.37.363z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M6.998 24.203A3.187 3.187 0 013.804 21c0-1.776 1.42-3.2 3.19-3.2A3.196 3.196 0 0110.2 21a3.191 3.191 0 01-3.202 3.203zm-.367-2.835H5.57c-.216 0-.37-.151-.37-.364s.15-.368.37-.368h1.06v-1.063c0-.217.15-.368.36-.368.216 0 .37.15.37.368v1.063h1.064c.216 0 .367.155.367.368a.352.352 0 01-.367.363H7.362v1.064c0 .213-.155.364-.372.364a.348.348 0 01-.36-.364v-1.064z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M6.63 21.367h.5v-.5h-.5v.5zm.732 0v-.5h-.5v.5h.5zm0-.73h-.5v.5h.5v-.5zm-.731 0v.5h.5v-.5h-.5zm.367 3.066A2.687 2.687 0 014.304 21h-1a3.687 3.687 0 003.694 3.703v-1zM4.304 21c0-1.5 1.197-2.7 2.69-2.7v-1a3.683 3.683 0 00-3.69 3.7h1zm2.69-2.7c1.5 0 2.702 1.201 2.706 2.701l1-.002A3.696 3.696 0 006.994 17.3v1zM9.7 21.002a2.691 2.691 0 01-2.702 2.702v1c2.05 0 3.706-1.65 3.702-3.704L9.7 21zm-4.129.866h1.06v-1H5.57v1zm.56-.5v1.064h1v-1.064h-1zm0 1.064c0 .485.37.864.86.864v-1c.019 0 .06.009.096.046a.14.14 0 01.044.09h-1zm.86.864a.853.853 0 00.87-.864h-1c0-.018.01-.06.046-.095a.13.13 0 01.083-.041v1zm.87-.864v-1.064h-1v1.064h1zm-.5-.564h1.064v-1H7.362v1zm1.064 0a.852.852 0 00.867-.863h-1c0-.019.01-.059.045-.095a.135.135 0 01.088-.041v1zm.867-.863a.853.853 0 00-.867-.868v1a.138.138 0 01-.09-.043.136.136 0 01-.043-.09h1zm-.867-.868H7.362v1h1.063v-1zm-.563.5v-1.063h-1v1.063h1zm0-1.063a.864.864 0 00-.254-.622.873.873 0 00-.618-.246v1a.132.132 0 01-.084-.041.14.14 0 01-.044-.091h1zm-.872-.868a.855.855 0 00-.614.252.87.87 0 00-.245.616h1c0 .01-.006.048-.042.085a.147.147 0 01-.099.047v-1zm-.86.868v1.063h1v-1.063h-1zm.5.563H5.572v1h1.06v-1zm-1.059 0a.87.87 0 00-.622.25.865.865 0 00-.249.618h1a.138.138 0 01-.043.09.134.134 0 01-.086.042v-1zm-.87.868c0 .499.388.863.87.863v-1a.13.13 0 01.083.041.144.144 0 01.046.096h-1zM6.997 26C9.743 26 12 23.742 12 21h-2c0 1.637-1.362 3-3.002 3v2zM12 21c0-2.743-2.263-5-5.006-5v2C8.636 18 10 19.364 10 21h2zm-5.006-5C4.252 16 2 18.26 2 21h2c0-1.639 1.36-3 2.994-3v-2zM2 21c0 2.741 2.257 5 4.998 5v-2C5.362 24 4 22.638 4 21H2z"
          mask="url(#path-2-outside-1_484_8754)"
        ></path>
      </svg>
    </div>
  );
};

export default ArchiveBoxWithPlus;
