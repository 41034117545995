import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const SummSpeechDuration: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 57 57">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          fillRule="evenodd"
          d="M27.487 41.125c0 .586-.476 1.074-1.099 1.074-.61 0-1.062-.488-1.062-1.074V17.26c0-.586.452-1.074 1.062-1.074.623 0 1.099.488 1.099 1.074v23.865zm8.398-9.253a2.408 2.408 0 00-2.16.134V19.958c0-.598.463-1.099 1.074-1.099.61 0 1.086.5 1.086 1.099v11.914zm-12.61 4.505c0 .585-.476 1.074-1.086 1.074-.61 0-1.074-.489-1.074-1.075V22.01a1.07 1.07 0 011.074-1.087c.61 0 1.086.489 1.086 1.087v14.367zm6.25-1.373c.443-1.1 1.625-1.966 2.161-2.16v-9.26c0-.599-.476-1.087-1.086-1.087a1.07 1.07 0 00-1.075 1.087v11.42zm-10.449-3.56c0 .599-.476 1.087-1.086 1.087a1.07 1.07 0 01-1.074-1.086V26.94c0-.598.463-1.098 1.074-1.098.61 0 1.086.5 1.086 1.098v4.505z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#EEF9FD"
          stroke="#EEF9FD"
          d="M40.36 38a8.025 8.025 0 11-16.052 0 8.025 8.025 0 0116.051 0z"
        ></path>
        <path
          fill="#2987CC"
          d="M32.334 35.357a.881.881 0 011.761 0v3.523a.88.88 0 01-.88.881H29.69a.88.88 0 010-1.761h2.643v-2.643z"
        ></path>
        <path
          fill="#2987CC"
          stroke="#2987CC"
          d="M39.76 38a7.427 7.427 0 11-14.853 0 7.427 7.427 0 0114.853 0zm-2.589 4.838a6.841 6.841 0 10-9.675-9.676 6.841 6.841 0 009.675 9.676z"
        ></path>
      </svg>
    </div>
  );
};

export default SummSpeechDuration;
