import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '@/store/base-query';
import serverRoutes from '@/utils/server-routes';
import { AuthResponse } from '@/interfaces/auth.type';

const authApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'authApi',
  tagTypes: ['AUTH'],
  endpoints: (build) => ({
    getAuth: build.mutation<AuthResponse, FormData>({
      query: (body) => ({
        url: serverRoutes.auth,
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    getRTKAuth: build.mutation<AuthResponse, { hash: string; node?: string }>({
      query: (body) => ({
        url: serverRoutes.hash,
        method: 'POST',
        body,
      }),
    }),
    hashLogin: build.mutation<AuthResponse, { hash: string }>({
      query: (body) => ({
        url: serverRoutes.hash,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetAuthMutation, useGetRTKAuthMutation, useHashLoginMutation } = authApi;

export default authApi;
