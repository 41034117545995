// import { Filter, FilterRange } from '@app/interfaces/filter';
import { useEffect, useRef } from 'react';

export function getFrontendDate(
  string: string | Date,
  template: 'dd.MM.yyyy' | 'dd.MM.yyyy HH:mm:ss' | 'dd.MM.yyyy HH:mm' | 'yyyy-MM-dd HH:mm:ss',
) {
  const date = string ? new Date(string) : new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();

  const values = {
    'dd.MM.yyyy': `${getDate(day)}.${getDate(month)}.${year}`,
    'HH:mm:ss': `${getDate(hour)}:${getDate(min)}:${getDate(sec)}`,
    'HH:mm': `${getDate(hour)}:${getDate(min)}`,
    'yyyy-MM-dd HH:mm:ss': `${year}-${getDate(month)}-${getDate(day)} ${getDate(hour)}:${getDate(
      min,
    )}:${getDate(sec)}`,
  };

  function getDate(date: string | number) {
    return date < 10 ? `0${date}` : date;
  }

  if (template === 'dd.MM.yyyy') {
    return values['dd.MM.yyyy'];
  } else if (template === 'dd.MM.yyyy HH:mm') {
    return `${values['dd.MM.yyyy']} ${values['HH:mm']}`;
  } else if (template === 'yyyy-MM-dd HH:mm:ss') {
    return values['yyyy-MM-dd HH:mm:ss'];
  } else if (template === 'dd.MM.yyyy HH:mm:ss') {
    return `${values['dd.MM.yyyy']} ${values['HH:mm:ss']}`;
  }
}

export function getNormalDateForNewDate(
  string: string,
  start_template: 'dd.MM.yyyy HH:mm:ss' | 'dd.MM.yyyy',
) {
  return start_template === 'dd.MM.yyyy HH:mm:ss'
    ? `${string.split(' ')[0].split('.').reverse().join('/')} ${string.split(' ')[1]}`
    : start_template === 'dd.MM.yyyy'
      ? `${string.split('.').reverse().join('/')}`
      : '';
}

export const isEllipsisText = (elem: HTMLElement) => {
  return elem.offsetWidth < elem.scrollWidth || elem.offsetHeight < elem.scrollHeight;
};

export function getStringDate(start_date: Date | string | null = null, server_format = true) {
  const date = start_date ? new Date(start_date) : new Date();
  const year = date.getFullYear();
  const correctedMonth = addLeadingZero(date.getMonth() + 1);
  const correctedDay = addLeadingZero(date.getDate());

  if (!server_format) {
    return `${correctedDay}.${correctedMonth}.${year}`;
  } else {
    return `${year}-${correctedMonth}-${correctedDay}`;
  }
}

export function getStringTime(start_date: Date | string | null = null, with_seconds = true) {
  const date = start_date ? new Date(start_date) : new Date();
  const correctedHours = addLeadingZero(date.getHours());
  const correctedMinutes = addLeadingZero(date.getMinutes());
  const correctedSeconds = addLeadingZero(date.getSeconds());

  if (with_seconds) {
    return `${correctedHours}:${correctedMinutes}:${correctedSeconds}`;
  } else {
    return `${correctedHours}:${correctedMinutes}`;
  }
}

export function addLeadingZero(datePart: number) {
  return datePart < 10 ? `0${datePart}` : String(datePart);
}

export const AutoFocusHook = (condition?: boolean) => {
  const inputFocusRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (condition) {
      setTimeout(() => {
        inputFocusRef.current?.focus();
      }, 0);
    } else inputFocusRef.current?.focus();
  }, [condition]);
  return {
    ref: inputFocusRef,
  };
};
