import React, { FC, ReactNode } from 'react';
import clsx from 'classnames';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { To } from 'history';

import { Icon } from '@ui';
import { ArrowLeftIcon } from '@icons';

import { IconNameType } from '@/components/ui/icons/icon-name.type';
import { CircleButton } from '@/components/ui/index';

type PageHeaderProps = {
  icon?: IconNameType;
  label: string | ReactNode;
  after?: IconNameType;
  afterAction?: () => void;
  popLink?: string | number;
  popLinkVoid?: () => void;
  children?: ReactNode;
};
const PageHeader: FC<PageHeaderProps> = (props) => {
  const { icon, children, popLinkVoid, label, after, popLink, afterAction } = props;
  const navigate = useNavigate();
  const circleButtonClassName = classNames(
    'w-[32px]',
    'h-[32px]',
    'bg-speech_analitics',
    'text-white',
    'inline-flex',
    'justify-center',
    'transition',
    'hover:opacity-80',
    'items-center',
    'rounded-full',
    'mr-[16px]',
  );

  const renderIconPageHeader = icon && (
    <Icon name={icon} size={26} className="text-speech_analitics_middle mr-[16px]" />
  );

  const renderAfterAction = after && (
    <div className="ml-[5px] mt-[5px]">
      <CircleButton
        icon={after}
        size={22}
        className="text-action"
        onClick={afterAction?.bind(null)}
      />
    </div>
  );

  const renderBackButton = popLink && (
    <div className="ml-[-10px]">
      <button onClick={() => navigate(popLink as To)} className={circleButtonClassName}>
        <ArrowLeftIcon size={19} className="text-white" />
      </button>
    </div>
  );
  const renderBackButtonWithVoid = popLinkVoid && (
    <div className="ml-[-10px]">
      <button onClick={popLinkVoid} className={circleButtonClassName}>
        <ArrowLeftIcon size={19} className="text-white" />
      </button>
    </div>
  );

  return (
    <div
      className={clsx(
        popLink ? 'pt-[32px] pb-[25px]' : 'pt-[22px] pb-[20px]',
        'inline-flex w-full items-center justify-between pl-[10px]  min-h-[85px]',
      )}
    >
      <div className="inline-flex h-full items-center">
        {renderBackButton}
        {renderBackButtonWithVoid}
        {renderIconPageHeader}
        <div className="font-[700] text-[24px] text-1color inline-flex items-center z-[1]">
          {label}
        </div>
        {renderAfterAction}
      </div>
      <div className="h-full inline-flex items-center">{children}</div>
    </div>
  );
};

export default PageHeader;
