import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, uriParamsDecoder } from '@/store/base-query';
import serverRoutes from '@/utils/server-routes';
import {
  Dictionary,
  DictionaryBulk,
  DictionaryWordAddResponse,
  DictionaryWordEditRequest,
  DictionaryWordEditResponse,
  DictionaryWordResponseData,
  WordEdition,
} from '@/interfaces/dictionary.type';
import { Pagination } from '@/interfaces/common.type';

const dictionaryApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'dictionaryApi',
  tagTypes: ['DICTIONARY', 'WORD'],
  endpoints: (build) => ({
    /* Получение данных по слову из словаря */
    getWordData: build.query<Dictionary, WordEdition>({
      query: (params) => ({
        url: serverRoutes.editorWord,
        params: { ...params, word: uriParamsDecoder(params.word) },
      }),
      providesTags: ['WORD'],
    }),
    /* Список слов в редактируемой модели */
    currentEditingWords: build.query<DictionaryBulk, Pagination>({
      query: (params) => ({
        url: serverRoutes.wordBulk,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.list.map((wordsList) => ({
                type: 'DICTIONARY' as const,
                id: wordsList.word,
              })),
              'DICTIONARY',
            ]
          : ['DICTIONARY'], // ['DICTIONARY'],
    }),
    /* Добавление слова в словарь */
    addWord: build.mutation<DictionaryWordAddResponse, DictionaryWordResponseData>({
      query: ({ params, body }) => ({
        url: serverRoutes.editorWord,
        method: 'POST',
        params,
        body: { ...body, ...params },
      }),
      invalidatesTags: ['DICTIONARY'],
    }),
    /* Редактирование слова в словаре */
    editWordInDictionary: build.mutation<DictionaryWordEditResponse, DictionaryWordEditRequest>({
      query: (body) => ({
        url: serverRoutes.editorWord,
        method: 'PUT',
        params: { model_id: body.model_id },
        body: body,
      }),
      invalidatesTags: ['DICTIONARY'],
    }),
    /* Удаление слова из словаря */
    removeWordFromDictionary: build.mutation<void, WordEdition>({
      query: (params) => ({
        url: serverRoutes.editorWord,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['DICTIONARY'],
    }),
  }),
});

export const {
  useRemoveWordFromDictionaryMutation,
  useAddWordMutation,
  useGetWordDataQuery,
  useEditWordInDictionaryMutation,
  useCurrentEditingWordsQuery,
  useLazyGetWordDataQuery,
  useLazyCurrentEditingWordsQuery,
} = dictionaryApi;
export default dictionaryApi;
