import React, { FC } from 'react';

import { LoadingIcon } from '@/components/ui/icons/icons-list';

const Loading: FC<{ text?: string }> = (props) => {
  const { text } = props;
  return (
    <div className="absolute inset-0 z-[99] w-full inline-flex items-center justify-center h-full bg-white backdrop-blur-sm bg-opacity-20">
      <div className="inline-flex flex-col items-center justify-center">
        <LoadingIcon size={100} />
        {text && (
          <div className="text-[12px] m-[-25px] opacity-40 pulse text-speech_analytics">{text}</div>
        )}
      </div>
    </div>
  );
};

export default Loading;
