import React, { FC } from 'react';

import { TableColumn, TableItem } from '../table.types';
type rowsPropsType = {
  row: TableItem;
  truncate: boolean;
  column: TableColumn;
};

export const Rows: FC<rowsPropsType> = (props) => {
  const { column, truncate, row } = props;
  return (
    <td
      height={46}
      className={`shadow-sm last:rounded-r-[10px]
                  first:rounded-l-[10px] ${truncate && 'truncate'}`}
      key={column.index}
    >
      <div className="flex">
        {column.divider === 'left' && (
          <div>
            <div className="flex-1 h-[28px] w-[1px] bg-b_light" />
          </div>
        )}
        <div
          className={`pl-[20px] last:pr-[20px] flex-1 ${truncate && 'truncate'}`}
          style={{ minWidth: `${column.minWidth}px` }}
        >
          {row?.[column.index]}
        </div>
        {column.divider === 'right' && (
          <div>
            <div className="flex-1 h-[28px] w-[1px] bg-b_light" />
          </div>
        )}
      </div>
    </td>
  );
};
