import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import * as uuid from 'uuid';
import cn from 'classnames';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Box, Button, CircleButton, Input, Loading, TooltipWrapper } from '@ui';
import { PlusIcon, SaveIcon } from '@icons';

import { Dictionary, DictionaryForm } from '@/interfaces/dictionary.type';
import useOnclickOutside from '@/hooks/use-onclick-outside';
import {
  ARRAY_FIELD_NAME,
  DEFAULT_PHONEMIZER_LANG,
  DEFAULT_VALIDATION_WEIGHT,
  NGRAM_VALIDATION_WEIGHT,
  PHONEMES_LANG,
} from '@/utils/constants';
import { useCheckPhonemizerMutation } from '@/store/api/phonomizer.api';
import {
  useAddWordMutation,
  useEditWordInDictionaryMutation,
  useGetWordDataQuery,
} from '@/store/api/dictionary.api';
import { useAddNgrammMutation, useEditNgrammMutation } from '@/store/api/ngramm.api';

export type EditBlockParams = {
  type?: 'edit' | 'new';
  word?: string | null;
  defaultValue?: Dictionary;
};

type DictionaryEditorFormBlockType = {
  modelID: string;
  editMode: EditBlockParams;
  editorHandler: Dispatch<SetStateAction<EditBlockParams | null>>;
};

const DictionaryEditFormBlock: FC<DictionaryEditorFormBlockType> = (props) => {
  console.log('props', props);
  const {
    editorHandler,
    modelID,
    editMode: { word, type: editModeType = 'edit', defaultValue },
  } = props;
  const { t } = useTranslation();

  const [submitEditWord, { isLoading: isEditWordLoading }] = useEditWordInDictionaryMutation();
  const [editNgram, { isLoading: isLoadingEditNgram }] = useEditNgrammMutation();
  const [addNgram, { isLoading: isLoadingAddNgram }] = useAddNgrammMutation();
  const [checkPhonemizer, { isLoading: isLoadingPhonemizer }] = useCheckPhonemizerMutation();
  const [addDictionaryWord] = useAddWordMutation();
  const {
    data: wordDataBlock,
    isLoading: isLoadingWordBlock,
    isFetching: isFetchingWordBlock,
  } = useGetWordDataQuery(
    { model_id: modelID, word: String(word) },
    {
      skip: editModeType === 'new',
      refetchOnMountOrArgChange: true,
    },
  );
  const isLoadingNgram = isLoadingAddNgram || isLoadingEditNgram;

  const [activePhonemePosition, changeActivePhonemePosition] = useState<Array<number> | null>(null);

  const {
    control: dictionaryControl,
    register: dictionaryRegister,
    reset: dictionaryReset,
    watch: dictionaryWatch,
    setValue: dictionarySetValue,
    formState: { errors },
  } = useForm<DictionaryForm>({
    defaultValues: (defaultValue && {
      ...defaultValue,
      weight: Number(defaultValue.weight),
      ngrams: defaultValue.ngrams.map((ngram) => ({
        text: ngram.value,
        weight: String(ngram.weight),
        temp_id: ngram.ngram_id,
      })),
    }) || {
      word: '',
      phonemes: [],
      weight: DEFAULT_VALIDATION_WEIGHT[0],
      ngrams: [],
    },
  });
  const {
    fields: ngramFields,
    append,
    insert,
    remove,
  } = useFieldArray({
    control: dictionaryControl,
    name: ARRAY_FIELD_NAME,
  });

  const currentOpenEditBlockRef = useRef<HTMLDivElement>(null);
  const phonemeRef = useRef<HTMLDivElement>(null);

  useOnclickOutside(currentOpenEditBlockRef, () => editorFormHandler('cancel'));
  useOnclickOutside(phonemeRef, () => changeActivePhonemePosition(null));

  useEffect(() => {
    dictionaryReset();
    editModeType === 'edit' &&
      wordDataBlock &&
      Object.entries(wordDataBlock).forEach(([fieldName, fieldValue]) => {
        if (fieldName === ARRAY_FIELD_NAME) {
          Object.values(fieldValue).forEach((fieldArrayValue, index) => {
            insert(index, { ...fieldArrayValue, temp_id: fieldArrayValue.ngram_id, type: 'old' });
          });
        } else {
          dictionarySetValue(
            fieldName as keyof DictionaryForm,
            fieldValue as string | Array<string> | number,
          );
        }
      });
  }, [dictionaryReset, dictionarySetValue, editModeType, insert, wordDataBlock]);

  function editorFormHandler(actionEvent: 'cancel' | 'edit' | 'new') {
    const { word, phonemes, weight } = dictionaryWatch();
    if (
      Number(weight) > NGRAM_VALIDATION_WEIGHT['1'][0] &&
      Number(weight) < NGRAM_VALIDATION_WEIGHT['1'][1]
    ) {
      toast.warning(
        `Вес слова должен быть в интервале <b>${NGRAM_VALIDATION_WEIGHT['1'].join(', ')}</b>`,
      );
      return;
    }
    switch (actionEvent) {
      case 'new': {
        addDictionaryWord({
          params: { model_id: modelID },
          body: {
            temp_id: uuid.v4(),
            word,
            phonemes: phonemes.map((phoneme) => ({ phoneme, temp_id: uuid.v4() })),
            weight,
            ngrams: [],
          },
        })
          .unwrap()
          .then(() => {});
        break;
      }
      case 'edit': {
        submitEditWord({
          word,
          model_id: modelID,
          phonemes: phonemes.map((phoneme) => ({ temp_id: uuid.v4(), phoneme })),
          weight,
        })
          .unwrap()
          .then((result) => {
            result.success && editNgramHandler('submit', null);
          })
          .catch(() => {
            toast.error('Не удалось отредактировать слово');
          })
          .finally(() => {
            editorHandler(null);
          });
        break;
      }
      default: {
        editorHandler(null);
      }
    }
  }

  function editNgramHandler(type: 'submit' | 'add' | 'remove', ngrammID: number | null) {
    switch (type) {
      case 'add': {
        append({
          text: '',
          weight: String(NGRAM_VALIDATION_WEIGHT['2'][3]),
          temp_id: uuid.v4(),
          type: 'new',
        });
        break;
      }
      case 'remove': {
        ngrammID && remove(ngrammID);
        break;
      }
      case 'submit': {
        const editErrorMessage = (text: string, isNew: boolean) => {
          if (isNew) {
            toast.error(`Не удалось добавить ${text}`);
            return;
          }
          toast.error(`Не удалось отредактировать ${text}`);
        };
        dictionaryWatch().ngrams.forEach((ngram, indexOfNgram) => {
          const ngramWordLength = ngram.text.split(' ').length;
          if (ngramWordLength < 2 && ngramWordLength > 3) {
            toast.warning('Ngramm должно быть от двух до трех слов');
            return;
          }
          if (
            Number(ngram.weight) > NGRAM_VALIDATION_WEIGHT[String(ngramWordLength)][0] &&
            Number(ngram.weight) < NGRAM_VALIDATION_WEIGHT[String(ngramWordLength)][1]
          ) {
            toast.warning(
              `${ngram.text} вес не соответствует доступным значениям ${NGRAM_VALIDATION_WEIGHT[
                String[ngramWordLength]
              ].join(', ')}`,
            );
            return;
          }
          ngram.type === 'old' &&
            wordDataBlock?.ngrams[indexOfNgram].weight !== Number(ngram.weight) &&
            editNgram({
              weight: Number(ngram.weight),
              ngram_id: ngram.temp_id,
              model_id: modelID,
            })
              .unwrap()
              .then((result) => {
                if (result.success) {
                  toast.success(`${ngram.text} успешно отредактирована`);
                } else {
                  editErrorMessage(ngram.text, false);
                }
              })
              .catch(() => editErrorMessage(ngram.text, false));
          ngram.type === 'new' &&
            addNgram({
              weight: Number(ngram.weight),
              value: ngram.text,
              temp_id: ngram.temp_id,
              model_id: modelID,
            })
              .unwrap()
              .then((result) => {
                if (result.success) {
                  toast.success(`${ngram.text} успешно добавлена`);
                } else {
                  editErrorMessage(ngram.text, true);
                }
              })
              .catch(() => {
                editErrorMessage(ngram.text, true);
              });
        });
        break;
      }
    }
  }

  function checkPhonemizerHandler() {
    if (dictionaryWatch().word.match(/[0-9]/g)?.length) {
      toast.warning(t('pages.editor.edit_form.toast_error.digital_extension'));
      return;
    }
    if (dictionaryWatch().word.length > 0) {
      checkPhonemizer({
        words: [{ id: uuid.v4(), word: dictionaryWatch().word }],
        lang: DEFAULT_PHONEMIZER_LANG,
      })
        .unwrap()
        .then((result) => {
          if (result.transcriptions)
            dictionarySetValue('phonemes', [
              ...dictionaryWatch().phonemes,
              ...result.transcriptions.map(({ transcription }) => transcription.join(' ')),
            ]);
        })
        .catch(() => {
          toast.error(t('pages.editor.toast_error.cant_translate'));
        });
    } else {
      toast.warning(t('pages.editor.toast_warning.not_word'));
    }
  }

  function changePhonemesHandler(options: {
    value: string | null;
    phonemeIndexPosition?: number;
    type?: 'position_translate' | 'position_phoneme' | 'remove_phoneme';
  }) {
    const { value, phonemeIndexPosition = 0, type = 'position_translate' } = options;
    const currentPhonemesState = dictionaryWatch().phonemes;
    switch (type) {
      case 'remove_phoneme': {
        dictionarySetValue(
          'phonemes',
          dictionaryWatch().phonemes.filter((_, index) => index !== phonemeIndexPosition),
        );
        break;
      }
      case 'position_phoneme': {
        dictionarySetValue('phonemes', [...dictionaryWatch().phonemes, PHONEMES_LANG[0]]);
        break;
      }
      case 'position_translate': {
        if (value === null) {
          dictionarySetValue(
            'phonemes',
            currentPhonemesState.map((phoneme, index) =>
              index === phonemeIndexPosition ? phoneme.split(' ').slice(0, -1).join(' ') : phoneme,
            ),
          );
          break;
        }
        if (activePhonemePosition) {
          const [phonemeIndex, position] = activePhonemePosition;
          if (currentPhonemesState.length < phonemeIndex) {
            dictionarySetValue('phonemes', [...currentPhonemesState, '']);
            changeActivePhonemePosition([phonemeIndex, 0]);
          } else {
            const updatePositionPhoneme = currentPhonemesState.map((phoneme, index) => {
              const currentPhoneme = phoneme.split(' ');
              const isRightPhoneme = index === phonemeIndex;
              if (currentPhoneme.length <= position && isRightPhoneme) {
                currentPhoneme.push(String(value));
                return currentPhoneme.join(' ');
              }
              return isRightPhoneme
                ? currentPhoneme
                    .map((phonemePart, phonemePartIndex) =>
                      position === phonemePartIndex ? value : phonemePart,
                    )
                    .join(' ')
                : phoneme;
            });
            dictionarySetValue(`phonemes`, updatePositionPhoneme);
          }
        }
        break;
      }
    }
    changeActivePhonemePosition(null);
  }

  return (
    <>
      <div className="absolute right-0 top-0 z-[99] w-full h-full bg-white backdrop-blur-sm bg-opacity-20">
        <div
          ref={currentOpenEditBlockRef}
          className="absolute bg-white shadow-medium  top-0 right-[0px] duration-100 translate-x-[0px] h-full w-[70%] z-30 p-[15px] mx-[10px]"
        >
          {isLoadingWordBlock || isFetchingWordBlock ? (
            <Loading text={t('pages.editor.edit_form.loading.data_loading')} />
          ) : (
            <>
              <div className="font-[700] min-h-[18px] text-[24px] text-1color inline-flex mb-[30px] items-center z-[1] truncate">
                {dictionaryWatch().word}
              </div>
              <div className="w-full mr-[15px] h-full overflow-x-auto pb-[150px]">
                <div className="flex flex-col gap-[15px]">
                  <div className="flex-1">
                    <div>{t('pages.editor.table.word_name')}</div>
                    <Input
                      disabled={editModeType === 'edit'}
                      {...dictionaryRegister('word', {
                        required: {
                          value: true,
                          message: t('pages.editor.edit_form.input.empty_field'),
                        },
                        pattern: {
                          value: /[a-z A-Zа-яА-ЯёЁ0-9-]/g,
                          message: t('pages.editor.edit_form.input.error_pattern'),
                        },
                      })}
                      error={errors.word?.message}
                      placeholder={t('pages.editor.table.word_name')}
                    />
                  </div>
                  <div>
                    <div className="inline-flex items-center">
                      {t('pages.editor.table.translate')}
                      {!isLoadingPhonemizer && (
                        <a
                          className="text-action pl-[10px] text-[11px] cursor-pointer"
                          onClick={() => {
                            checkPhonemizerHandler();
                          }}
                        >
                          {t('pages.editor.edit_form.find_translate')}
                        </a>
                      )}
                      <TooltipWrapper
                        content={t('pages.editor.edit_form.tooltips.auto_translate')}
                        id="translate_questions"
                      >
                        <span className="text-action text-[12px] px-[10px]">?</span>
                      </TooltipWrapper>
                    </div>
                    <div className="inline-flex w-full flex-col gap-[5px]">
                      {dictionaryWatch().phonemes?.map((phoneme, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-between relative border-b_dark rounded-defaultR p-[4px] text-0color border-[1px] text-sm"
                          >
                            <div className="flex gap-[1px] items-center justify-between">
                              {phoneme.split(' ').map((phonemePart, partIndex) => (
                                <div
                                  key={partIndex}
                                  onClick={() => changeActivePhonemePosition([index, partIndex])}
                                  className={cn(
                                    activePhonemePosition?.[0] === index &&
                                      activePhonemePosition?.[1] == partIndex &&
                                      'bg-pastel_action',
                                    'hover:bg-pastel_action relative transition p-[3px] rounded-[3px] cursor-pointer select-none',
                                  )}
                                >
                                  {phonemePart}
                                </div>
                              ))}
                              {activePhonemePosition && index === activePhonemePosition[0] && (
                                <div
                                  ref={activePhonemePosition?.[0] === index ? phonemeRef : null}
                                  className="absolute bottom-[-25px] py-[3px] px-[10px] select-none rounded-defaultR bg-pastel_action z-20 flex flex-wrap gap-[4px]"
                                >
                                  {PHONEMES_LANG.map((accessiblePhonemes) => (
                                    <div
                                      key={accessiblePhonemes}
                                      className=" opacity-90 hover:bg-white p-[2px] cursor-pointer hover:opacity-100"
                                      onClick={() =>
                                        changePhonemesHandler({ value: accessiblePhonemes })
                                      }
                                    >
                                      {accessiblePhonemes}
                                    </div>
                                  ))}
                                </div>
                              )}
                              <TooltipWrapper content="Добавить символ" id={`add_symbol_${index}`}>
                                <div className="ml-[10px] select-none">
                                  <CircleButton
                                    icon="PlusIcon"
                                    size={10}
                                    className="hover:bg-pastel_action transition p-[3px] rounded-[3px]"
                                    onClick={() =>
                                      changeActivePhonemePosition([
                                        index,
                                        phoneme.split(' ').length + 1,
                                      ])
                                    }
                                  />
                                </div>
                              </TooltipWrapper>
                              <TooltipWrapper
                                content={t('pages.editor.edit_form.tooltips.remove_symbol')}
                                id={`remove_symbol_${index}`}
                              >
                                <div className="ml-[3px]">
                                  <CircleButton
                                    icon="ArrowLeftIcon"
                                    className="hover:bg-dangerBg hover:text-white transition p-[3px] rounded-[3px]"
                                    size={10}
                                    onClick={() =>
                                      changePhonemesHandler({
                                        value: null,
                                        phonemeIndexPosition: index,
                                      })
                                    }
                                  />
                                </div>
                              </TooltipWrapper>
                            </div>
                            <div>
                              <CircleButton
                                icon="TrashIcon"
                                className="hover:bg-dangerBg hover:text-white transition p-[3px] rounded-[3px]"
                                onClick={() =>
                                  changePhonemesHandler({
                                    value: null,
                                    phonemeIndexPosition: index,
                                    type: 'remove_phoneme',
                                  })
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                      <div className="mt-[10px]">
                        <Button
                          fill="outlined"
                          label={t('pages.editor.edit_form.button.add_translate')}
                          onClick={() =>
                            changePhonemesHandler({ value: null, type: 'position_phoneme' })
                          }
                          isFull
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Input
                      step={0.000001}
                      type="number"
                      {...dictionaryRegister('weight')}
                      label={t('pages.editor.table.weight')}
                    />
                  </div>
                  <div>
                    <div>N граммы </div>
                    <div className="sticky z-40 w-full bg-white top-0 p-[10px] transition ease-in-out rounded-defaultR shadow-medium">
                      <div className="flex items-center h-full w-full justify-start gap-[10px]">
                        {isLoadingNgram ? (
                          <>Сохранение изменений</>
                        ) : (
                          <>
                            <TooltipWrapper content="Добавить ngram" id={`add_ngram_id_${modelID}`}>
                              <div
                                className="p-[8px] cursor-pointer bg-2color rounded-defaultR shadow-medium flex items-center justify-center"
                                onClick={editNgramHandler.bind(null, 'add', null)}
                              >
                                <PlusIcon className="text-white" size={18} />
                              </div>
                            </TooltipWrapper>
                            {editModeType === 'edit' && (
                              <TooltipWrapper
                                content="Сохранить ngram без редактирования слова"
                                id={`save_ngram_id_${modelID}`}
                              >
                                <div
                                  className="cursor-pointer text-white flex items-center gap-[5px] p-[8px] bg-green rounded-defaultR shadow-medium  truncate justify-center"
                                  onClick={editNgramHandler.bind(null, 'submit', null)}
                                >
                                  <SaveIcon className="text-white" size={18} />
                                </div>
                              </TooltipWrapper>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-[10px]">
                      {ngramFields.map((ngramField, index) => (
                        <Box
                          key={ngramField.id}
                          className="group relative p-[10px] flex items-center  gap-x-[10px] hover:border-action border-bg-4  border-[1px]  transition ease-in-out w-full bg-action rounded-defaultR shadow-medium"
                        >
                          <div className="basis-1/2">
                            <label className="text-2color">Нграмм</label>
                            <Input
                              disabled={dictionaryWatch().ngrams[index].type === 'old'}
                              {...dictionaryRegister(`ngrams.${index}.text`)}
                            />
                          </div>
                          <div className="basis-1/2">
                            <label className="text-2color">Вес</label>
                            <Input
                              type="number"
                              step={0.000001}
                              {...dictionaryRegister(`ngrams.${index}.weight`)}
                            />
                          </div>
                          <div className="group-hover:block hidden mt-[25px]">
                            <TooltipWrapper
                              content="Удалить ngram"
                              id={`remove_ngram_id_${modelID}`}
                            >
                              <CircleButton
                                icon="TrashIcon"
                                size={18}
                                className="text-white p-[8px] cursor-pointer bg-basic_red rounded-defaultR shadow-medium flex items-center justify-center"
                                onClick={editNgramHandler.bind(null, 'remove', index)}
                              />
                            </TooltipWrapper>
                          </div>
                        </Box>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-end mt-[10px] gap-[10px] p-[20px] bg-white px-[25px] shadow-medium absolute bottom-0">
                  <Button
                    label="Отмена"
                    fill="outlined"
                    onClick={editorHandler.bind(null, null, 'cancel')}
                  />
                  <Button
                    disabled={isEditWordLoading}
                    label={
                      editModeType === 'new'
                        ? t('pages.editor.edit_form.button.add')
                        : t('pages.editor.edit_form.button.save')
                    }
                    onClick={editorFormHandler.bind(null, editModeType)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DictionaryEditFormBlock;
