import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const Calculated: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 57 57">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          d="M20.083 38.5a4.75 4.75 0 01-4.75-4.75V21.083a4.75 4.75 0 014.75-4.75 1.583 1.583 0 100-3.166 7.917 7.917 0 00-7.916 7.916V33.75a7.916 7.916 0 007.916 7.917 1.583 1.583 0 100-3.167zM37.5 21.083v9.5a1.583 1.583 0 103.167 0v-9.5a7.917 7.917 0 00-7.917-7.916 1.584 1.584 0 000 3.166 4.75 4.75 0 014.75 4.75zM42.25 38.5h-1.583v-1.583a1.583 1.583 0 10-3.167 0V38.5h-1.583a1.583 1.583 0 100 3.167H37.5v1.583a1.583 1.583 0 103.167 0v-1.583h1.583a1.583 1.583 0 100-3.167z"
        ></path>
        <path
          fill="#2987CC"
          d="M19 34.156h2.001V23.708h.079l4.257 10.448h1.629l4.267-10.448h.069v10.448h2V20h-2.53L26.18 31.301h-.059L21.541 20H19v14.156z"
        ></path>
      </svg>
    </div>
  );
};

export default Calculated;
