import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BrokenRobotIcon, ErrorIcon } from '@icons';

import navigationRoutes from '@/utils/navigation-routes';

function NotFoundPage() {
  const { t } = useTranslation();
  const error = useRouteError() as string;

  if (process.env.NODE_ENV === 'development')
    return (
      <div className="w-screen h-screen inline-flex items-center justify-center">
        <div className="flex flex-col items-center">
          <BrokenRobotIcon size={65} className="text-speech_analitics" />
          <h1 className="text-[20px] text-center font-[500] uppercase">
            {t('pages.notFound.title')}
          </h1>
          <code className="bg-speech_analitics rounded-[10px] shadow-medium p-[10px] text-white w-[400px] text-[10px] font-bold">
            {String(error)}
          </code>
          <Link
            className="text-active text-center hover:opacity-80 lowercase"
            to={navigationRoutes.main}
          >
            {t('pages.notFound.link.default')}
          </Link>
        </div>
      </div>
    );
  return (
    <div className="w-full h-full inline-flex items-center justify-center">
      <div className="inline-flex items-center flex-col justify-center p-[20px]">
        <ErrorIcon size={120} className="text-speech_analitics_light" />
        <div className="font-bold text-[28px] uppercase text-speech_analitics">
          {t('pages.notFound.title')}
        </div>
        <Link to={navigationRoutes.main} className="text-defaultColor">
          {t('pages.notFound.link.default')}
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
