import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { Icon } from '@ui';
import { useTranslation } from 'react-i18next';

import { IconNameType } from '@/components/ui/icons/icon-name.type';
import navigationRoutes from '@/utils/navigation-routes';

type FallbackPageProps = {
  text?: string;
  link?: string;
  linkName?: string;
  icon?: IconNameType;
};

const FallbackPage: FC<FallbackPageProps> = (props) => {
  const { text, linkName, icon = 'ErrorIcon', link = navigationRoutes.main } = props;
  const { t } = useTranslation('pages.notFound');
  return (
    <div className="w-full h-full inline-flex items-center justify-center">
      <div className="inline-flex items-center flex-col justify-center p-[20px]">
        <Icon size={120} name={icon} className="text-speech_analitics_light" />
        <div className="font-bold text-[28px] uppercase text-speech_analitics">
          {t(`${text}`) || t('pages.notFound.title')}
        </div>
        <Link to={link} className="text-defaultColor">
          {linkName || link}
        </Link>
      </div>
    </div>
  );
};

export default FallbackPage;
