import React from 'react';
import { Edit2Icon } from '@icons';

const EmptyModelBlock = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex gap-[10px] items-center">
        <div>
          <Edit2Icon size={25} className="text-action" />
        </div>
        <div className="">Модель недоступна для редактирования</div>
      </div>
    </div>
  );
};

export default EmptyModelBlock;
