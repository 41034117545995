import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const BookOpenIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-icon"
        width={size}
        style={{ verticalAlign: 'middle' }}
        fill="currentColor"
        overflow="hidden"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 938.667c-25.6 0-42.667-17.067-42.667-42.667 0-46.933-38.4-85.333-85.333-85.333H85.333c-25.6 0-42.666-17.067-42.666-42.667V128c0-25.6 17.066-42.667 42.666-42.667h256c119.467 0 213.334 93.867 213.334 213.334V896c0 25.6-17.067 42.667-42.667 42.667zM128 725.333h256c29.867 0 59.733 8.534 85.333 21.334v-448c0-72.534-55.466-128-128-128H128v554.666z"></path>
        <path d="M512 938.667c-25.6 0-42.667-17.067-42.667-42.667V298.667c0-119.467 93.867-213.334 213.334-213.334h256c25.6 0 42.666 17.067 42.666 42.667v640c0 25.6-17.066 42.667-42.666 42.667H640c-46.933 0-85.333 38.4-85.333 85.333 0 25.6-17.067 42.667-42.667 42.667zm170.667-768c-72.534 0-128 55.466-128 128v448c25.6-12.8 55.466-21.334 85.333-21.334h256V170.667H682.667z"></path>
      </svg>
    </div>
  );
};

export default BookOpenIcon;
