import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

const Box: FC<{ children: ReactNode; className?: string; onClick?: () => void }> = (props) => {
  const { children, className, onClick } = props;
  return (
    <div
      className={cn('bg-white', 'shadow-medium', 'rounded-[10px]', className)}
      onClick={() => onClick?.()}
    >
      {children}
    </div>
  );
};

export default Box;
