import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const Pause: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 17 16">
        <path
          fill="currentColor"
          d="M8.5 15.851c4.19 0 7.64-3.45 7.64-7.64 0-4.181-3.458-7.638-7.647-7.638C4.31.573.86 4.03.86 8.212c0 4.19 3.45 7.64 7.639 7.64zm-2.197-4.607c-.403 0-.608-.227-.608-.564V5.736c0-.33.205-.564.608-.564h.827c.403 0 .601.235.601.564v4.944c0 .337-.198.564-.6.564h-.828zm3.581 0c-.403 0-.608-.227-.608-.564V5.736c0-.33.205-.564.608-.564h.828c.395 0 .593.235.593.564v4.944c0 .337-.198.564-.593.564h-.828z"
        ></path>
      </svg>
    </div>
  );
};

export default Pause;
