import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/store/base-query';
import serverRoutes from '@/utils/server-routes';
import { BModel, BModelCreate, UModelBulk } from '@/interfaces/model.type';
import { Pagination } from '@/interfaces/common.type';

const modelApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'modelApi',
  tagTypes: ['MODEL'],
  endpoints: (build) => ({
    modelBuild: build.query({
      query: () => ({
        url: serverRoutes.modelBulk,
      }),
    }),
    /* Получение списка моделей, доступных для клонирования */
    canCloneModelBulk: build.query<Array<BModel>, void>({
      query: () => ({
        url: serverRoutes.baseModelBulk,
      }),
    }),
    /* Создание пользовательской модели распознавания */
    createUserModel: build.mutation<string, BModelCreate>({
      query: (body) => ({
        url: serverRoutes.crudModel,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MODEL'],
    }),
    /* Удаление пользовательской модели распознавания */
    deleteUserModel: build.mutation<void, { id: string }>({
      query: (params) => ({
        url: serverRoutes.crudModel,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['MODEL'],
    }),
    /* Список пользовательских моделей распознавания */
    userModelBulk: build.query<UModelBulk, Pagination>({
      query: () => ({
        url: serverRoutes.userModelBulk,
      }),
    }),
  }),
});

export const {
  useModelBuildQuery,
  useUserModelBulkQuery,
  useDeleteUserModelMutation,
  useCreateUserModelMutation,
  useCanCloneModelBulkQuery,
} = modelApi;
export default modelApi;
