import React from 'react';
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import { rootRoute } from '@/routes/root-route';
import store from '@/store';
const App = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={rootRoute} />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 99999999 }}
      />
    </Provider>
  );
};

export default App;
