import { createSlice } from '@reduxjs/toolkit';

import { AuthUserData } from '@/interfaces/auth.type';
import { accessToken } from '@/utils/constants';
import { UserPermissions } from '@/store/types/auth';

export type AuthInitialState = {
  isLoginIn: boolean;
  accessToken: string | null;
  permissions?: UserPermissions['permissions'] | null;
  user: AuthUserData | null;
  userInfo: UserPermissions['user'] | null;
  tenant: UserPermissions['tenant'] | null;
};

const initialState: AuthInitialState = {
  isLoginIn: false,
  permissions: undefined,
  accessToken: localStorage.getItem('REACT_TOKEN_AUTH_KEY'),
  user: null,
  userInfo: null,
  tenant: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (state, { payload }: { payload: UserPermissions }) => {
      state.userInfo = payload.user;
      state.permissions = payload.permissions;
      state.tenant = payload.tenant;
    },
    logout: (state) => {
      state.accessToken = null;
      state.userInfo = null;
      state.permissions = null;
      state.tenant = null;
      localStorage.removeItem(accessToken);
    },
  },
});
export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
