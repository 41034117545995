import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, Input, PageHeader, Select, TextArea } from '@ui';

import navigationRoutes from '@/utils/navigation-routes';
import useQuery from '@/hooks/use-query';
import { BModelCreate } from '@/interfaces/model.type';
import {
  useCanCloneModelBulkQuery,
  useCreateUserModelMutation,
  useUserModelBulkQuery,
} from '@/store/api/model.api';

const NewEditorPage = () => {
  const { t } = useTranslation();
  const query = useQuery();

  const [createUserModel, { isLoading: isCreateFetching }] = useCreateUserModelMutation();
  const {
    data: canCloneModels,
    isFetching: isCanCloneFetching,
    isLoading: isCanCloneLoading,
    isSuccess: isCanCloneSuccess,
  } = useCanCloneModelBulkQuery();

  const isLoadingStack = isCanCloneFetching || isCanCloneLoading;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<BModelCreate>();

  function submitNewModelHandler(formData: BModelCreate) {
    const baseModelClone = canCloneModels?.find((model) => model.id === formData.base_model);
    if (baseModelClone) {
      createUserModel({
        ...formData,
        base: Boolean(baseModelClone?.base),
      })
        .unwrap()
        .then(() => {
          toast.success(t('pages.editor.success.model_created'));
          reset();
        });
    } else {
      toast.error(t('pages.editor.errors.can_find_base_model'));
    }
  }
  const { data } = useUserModelBulkQuery({});
  console.log('userModel', data);

  useEffect(() => {
    isCanCloneSuccess && setValue('base_model', canCloneModels[0].id);
  }, [canCloneModels, isCanCloneSuccess, setValue]);

  return (
    <div className="pb-[20px]">
      <PageHeader
        popLink={navigationRoutes.main}
        icon="PlusIcon"
        label={t('pages.editor.title_index')}
      ></PageHeader>
      {isLoadingStack && <>{t('pages.editor.loading')}</>}
      <form onSubmit={handleSubmit(submitNewModelHandler)} className="flex flex-col gap-y-[15px]">
        <div className="w-full max-w-[400px]">
          <h3 className="mb-[10px]">{t('pages.editor.page_title')}</h3>
          <Select
            placeholder={t('pages.editor.title_index')}
            defaultValue={watch()['base_model'] || query.get('model') || canCloneModels?.[0].id}
            onChange={(modelID) => setValue('base_model', modelID as string)}
            options={
              canCloneModels
                ? canCloneModels.map(({ name, id }) => ({ title: name, value: id }))
                : []
            }
          />
        </div>
        <div>
          <Input
            {...register('name', {
              required: {
                value: true,
                message: t('pages.editor.form.create_model.name.error.required'),
              },
            })}
            error={errors.name?.message}
            label={t('pages.editor.form.create_model.name.label')}
          />
        </div>
        <div>
          <TextArea
            label={t('pages.editor.form.create_model.description.label')}
            {...register('description', {
              required: {
                value: true,
                message: t('pages.editor.form.create_model.description.error.required'),
              },
            })}
            error={errors.description?.message}
          />
        </div>
        <div className="mt-[10px]">
          <Button
            disabled={isCreateFetching}
            type="submit"
            label={t('pages.editor.button.add_model')}
          />
        </div>
      </form>
    </div>
  );
};

export default NewEditorPage;
