export const serverPrefix = 'api/front/usr';
export const serverCommonPrefix = 'api/front';
export const serverAuthPrefix = 'api/auth';

const serverRoutes = {
  modelBulk: `/${serverPrefix}/model/bulk`,
  refresh_token: `/${serverAuthPrefix}/refresh`,
  baseModelBulk: `/${serverPrefix}/b_model/bulk`,
  crudModel: `/${serverPrefix}/u_model`,
  userModelBulk: `/${serverPrefix}/u_model/bulk`,
  editorSwitch: `/${serverPrefix}/model_editor/switch`,
  editorWord: `/${serverPrefix}/model_editor/word`,
  wordBulk: `/${serverPrefix}/model_editor/word/bulk`,
  ngramm: `/${serverPrefix}/model_editor/ngram`,
  ngrammBulk: `/${serverPrefix}/model_editor/ngram/bulk`,
  umodel: `/${serverPrefix}/model_editor/umodel`,
  // auth
  auth: `/${serverAuthPrefix}/login`,
  authMe: `/${serverCommonPrefix}/me`,
  hash: `/${serverAuthPrefix}/hashlogin`,
  phonemizer: `${serverCommonPrefix}/phonemizer/get_transcription`,
};

export default serverRoutes;
