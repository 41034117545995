import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const SearchIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
      >
        <g clipPath="url(#clip0_5_4088)">
          <path
            fill="#000"
            d="M31.781 30.1l-8.062-8.063a.742.742 0 00-.532-.218h-.643A12.944 12.944 0 0026 13c0-7.181-5.819-13-13-13S0 5.819 0 13s5.819 13 13 13c3.406 0 6.5-1.313 8.819-3.45v.637c0 .2.081.388.219.532L30.1 31.78a.75.75 0 001.063 0l.618-.618a.75.75 0 000-1.063zM13 24C6.919 24 2 19.081 2 13S6.919 2 13 2s11 4.919 11 11-4.919 11-11 11z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_5_4088">
            <path fill="#fff" d="M0 0H32V32H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default SearchIcon;
