import React, { MouseEvent, ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { toast } from 'react-toastify';
import * as uuid from 'uuid';

import {
  Box,
  Button,
  CircleButton,
  EasyModal,
  PageHeader,
  Paginator,
  Skeleton,
  TooltipWrapper,
  UploadButton,
} from '@ui';
import { TrashIcon } from '@icons';

import FilterBlock from './blocks/filter.block';
import DictionaryEditFormBlock, { EditBlockParams } from './blocks/dictionary-edit-form.block';

import { tableColumnClassName } from '@/components/ui/rubber-table/table.styles';
import { Dictionary, NgrammDictionary } from '@/interfaces/dictionary.type';
import {
  useCurrentEditingWordsQuery,
  useRemoveWordFromDictionaryMutation,
} from '@/store/api/dictionary.api';
import { useDeleteNgrammMutation } from '@/store/api/ngramm.api';
import { Pagination } from '@/interfaces/common.type';
import { PaginationResponse } from '@/components/ui/paginator/paginator';
import EmptyModelBlock from '@/pages/editor/blocks/empty-model.block';
import { PHONEMES_LANG } from '@/utils/constants';

export type PaginationChangeArgs = {
  paginationType: keyof Pagination;
  paginationValue: Pagination[keyof Pagination];
};

const DEFAULT_PAGINATION: Pagination = {
  offset: 0,
  limit: 15,
  sortDesc: false,
};

const EditorPage = () => {
  const { t } = useTranslation();
  const [currentDictionary, changeCurrentDictionary] = useState<Array<Dictionary>>([]);
  const [preloadFileFlag, changePreloadFileFlag] = useState<boolean>(false);
  const [editBlock, changeEditBlock] = useState<EditBlockParams | null>(null);
  const [removeWord, setRemoveWord] = useState<string | null>(null);
  const [paginationState, changePaginationState] = useState<Pagination>(DEFAULT_PAGINATION);
  const [deleteWordRequest, { isLoading: isFetchingRemoveWord }] =
    useRemoveWordFromDictionaryMutation();

  const {
    data: dictionaryFromServer,
    isLoading,
    isFetching,
    isError,
  } = useCurrentEditingWordsQuery(
    paginationState?.filter?.length && paginationState?.filter?.length > 3
      ? paginationState
      : { ...paginationState, filter: '' },
  );
  const [removeNgramm] = useDeleteNgrammMutation();

  const isLoadingModelWords = isLoading || isFetching;

  useEffect(() => {
    if (dictionaryFromServer?.list) {
      changeCurrentDictionary(dictionaryFromServer.list);
    }
  }, [changeCurrentDictionary, dictionaryFromServer]);

  useEffect(() => {
    return () => changePreloadFileFlag(false);
  }, []);

  function onLoadFile(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      const reader = new FileReader();
      reader.addEventListener('load', (textFile) => {
        const text = textFile.target?.result;
        if (text) {
          changePreloadFileFlag(true);
          const fields = String(text).split('\r\n');
          changeCurrentDictionary(
            fields.map((fileField) => {
              const [word = '', phonemesFromFile = '', weightFromFile = '-3', ngrams] =
                fileField.split(' | ');
              console.log('ngram', ngrams);
              return {
                word,
                phonemes: phonemesFromFile.split(','),
                weight: Number(weightFromFile),
                ngrams: ngrams.split(',').map((ngram) => {
                  const [value, weight] = ngram.split('/');
                  return {
                    ngram_id: uuid.v4(),
                    value,
                    weight: Number(weight),
                  };
                }) as unknown as NgrammDictionary[],
                error: false,
              };
            }),
          );
        }
      });
      if (event.target.files) {
        reader.readAsText(event.target.files[0]);
      }
    }
  }
  function openRemoveWordModal({
    event,
    word,
  }: {
    event: MouseEvent<HTMLDivElement>;
    word: string;
  }) {
    event.preventDefault();
    event.isPropagationStopped();
    setRemoveWord(word);
  }
  function removeWordHandler() {
    const errorMessage = () => toast.error('Не удалось удалить слово');
    if (removeWord && dictionaryFromServer) {
      deleteWordRequest({ model_id: dictionaryFromServer.model_id, word: removeWord })
        .unwrap()
        .catch(() => errorMessage());
    } else {
      errorMessage();
    }
  }

  function updatePaginationHandler({ paginationType, paginationValue }: PaginationChangeArgs) {
    changePaginationState((prevState) => ({ ...prevState, [paginationType]: paginationValue }));
  }

  function changePaginateParamsHandler(paginationParams: PaginationResponse) {
    Object.entries(paginationParams).forEach(([paginationKey, paginationValue]) =>
      updatePaginationHandler({
        paginationType: paginationKey as keyof Pagination,
        paginationValue,
      }),
    );
  }

  if (isError) return <EmptyModelBlock />;

  function openEditModalHandler({
    type,
    dictionary,
  }: {
    type: EditBlockParams['type'];
    dictionary?: Dictionary;
  }) {
    changeEditBlock({
      ...(preloadFileFlag
        ? { type: 'new', defaultValue: dictionary }
        : {
            type,
            word: dictionary?.word,
          }),
    });
  }
  function validateOptionsHandler(params: {
    type: 'phoneme' | 'ngram';
    value: string;
    text?: string;
  }) {
    const { type, value, text } = params;
    if (type === 'phoneme') {
      const concatArray = [...value.split(''), ...PHONEMES_LANG];
      console.log(new Set(concatArray).size, PHONEMES_LANG.length);
      return new Set(concatArray).size === PHONEMES_LANG.length;
    }
    if (type === 'ngram') {
      return text ? value.match(text)?.length : false;
    }
  }

  function removeNgrammHandler(ngramValue: string, params: { model_id: string; ngram_id: string }) {
    removeNgramm(params)
      .unwrap()
      .then(() => {
        toast.success(`${ngramValue} успешно удалена`);
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Не удалось удалить ${ngramValue}`);
      })
      .finally(() => {
        setRemoveWord(null);
      });
  }

  const loadingSkeletonRender = () => (
    <div className="flex gap-y-[10px] flex-col my-[15px] w-full">
      {Array(5)
        .fill('-')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map((_, index) => (
          <Skeleton key={index} width="100%" height={60} />
        ))}
    </div>
  );

  const validationMarkerRender = () => (
    <div className="absolute text-white top-[-10px] left-[-10px] rounded-[14px] flex items-center justify-center w-[30px] h-[30px] bg-basic_red">
      <TooltipWrapper content={'Неверный параметр'} id={uuid.v4()}>
        <span className="block text-white">!</span>
      </TooltipWrapper>
    </div>
  );

  return (
    <div>
      {dictionaryFromServer && editBlock && (
        <DictionaryEditFormBlock
          modelID={dictionaryFromServer.model_id}
          editorHandler={changeEditBlock}
          editMode={editBlock}
        />
      )}
      <PageHeader
        label={
          isLoadingModelWords || isFetchingRemoveWord ? (
            <Skeleton width={150} height={18} />
          ) : (
            `${t('pages.editor.title')} ${dictionaryFromServer?.name}`
          )
        }
      >
        <div className="flex items-center justify-center gap-[20px]">
          <Button
            onClick={() => changeEditBlock({ type: 'new' })}
            label={t('pages.editor.button.add')}
          />
          <UploadButton label={t('pages.editor.button.upload_file')} onUploadFile={onLoadFile} />
        </div>
      </PageHeader>
      <FilterBlock onChangePagination={updatePaginationHandler} />
      {isLoadingModelWords || isFetchingRemoveWord ? (
        loadingSkeletonRender()
      ) : (
        <div className="inline-flex w-full flex-col gap-y-2 mt-[30px]">
          <div className="grid grid-cols-[1fr_1fr_1fr_1fr_50px] w-full uppercase p-[10px]">
            <div className={tableColumnClassName}>{t('pages.editor.table.word_name')}</div>
            <div className={tableColumnClassName}>{t('pages.editor.table.translate')}</div>
            <div className={tableColumnClassName}>{t('pages.editor.table.weight')}</div>
            <div className={tableColumnClassName}>{t('pages.editor.table.ngram')}</div>
            <div className={tableColumnClassName}></div>
          </div>
          {preloadFileFlag && (
            <div className="w-full inline-flex items-center justify-start gap-[10px]">
              <Button fill="outlined" label="Вернуться к списку" />
              <div>
                <h3>Слова в файле</h3>
              </div>
            </div>
          )}
          {dictionaryFromServer &&
            currentDictionary?.map((dictionaryCell, index) => {
              return (
                <Box
                  key={index}
                  className={cn(
                    'bg-white w-full min-h-[40px] opacity-90 hover:bg-pastel_action hover:z-20 pl-[10px] hover:translate-x-[-3px] transition cursor-pointer',
                  )}
                >
                  <div className="grid grid-cols-[1fr_1fr_1fr_1fr_50px] w-full relative">
                    <div
                      className="block w-full h-full font-bold p-[10px]"
                      onClick={() =>
                        openEditModalHandler({ type: 'edit', dictionary: dictionaryCell })
                      }
                    >
                      {dictionaryCell.word}
                    </div>
                    <div
                      className=" w-full h-full font-bold p-[10px] block"
                      onClick={() =>
                        openEditModalHandler({ type: 'edit', dictionary: dictionaryCell })
                      }
                    >
                      <div className=" flex gap-[3px] relative flex-wrap">
                        {dictionaryCell.phonemes.map((phonemes, index) => (
                          <div key={index} className="bg-action text-white p-[3px] rounded-[5px]">
                            {preloadFileFlag &&
                              !validateOptionsHandler({ type: 'phoneme', value: phonemes }) &&
                              validationMarkerRender()}
                            {phonemes}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="block w-full h-full font-bold p-[10px]"
                      onClick={() =>
                        openEditModalHandler({ type: 'edit', dictionary: dictionaryCell })
                      }
                    >
                      {dictionaryCell.weight}
                    </div>
                    <div className="relative flex gap-[5px] flex-wrap w-full h-full font-bold p-[10px]">
                      {dictionaryCell.ngrams.map((ngram) => {
                        return (
                          <div
                            key={ngram.ngram_id}
                            className="group relative bg-action text-white rounded-[5px] flex justify-between hover:z-20 "
                          >
                            {preloadFileFlag &&
                              !validateOptionsHandler({
                                type: 'ngram',
                                value: ngram.value,
                                text: dictionaryCell.word,
                              }) &&
                              validationMarkerRender()}
                            <div className="p-[3px] px-[10px]">
                              <div className="text-12px text-white">{ngram.value}</div>
                              <div className="text-10px text-white font-[300]">{ngram.weight}</div>
                            </div>
                            {!preloadFileFlag && (
                              <TooltipWrapper
                                content={`Удалить ${ngram.value}`}
                                id={`edit_ngram_${ngram.ngram_id}`}
                              >
                                <div
                                  className="absolute hidden transform duration-[1000] group-hover:block z-20 right-[-10px] w-[20px] h-full  items-center justify-center px-[20px] rounded-r-defaultR"
                                  onClick={() =>
                                    removeNgrammHandler(ngram.value, {
                                      model_id: dictionaryFromServer.model_id,
                                      ngram_id: ngram.ngram_id,
                                    })
                                  }
                                >
                                  <div className="flex h-full w-full">
                                    <div className="bg-basic_red flex items-center justify-center basis-1/2 p-[10px] rounded-r-defaultR">
                                      <TrashIcon size={10} className="text-white h-full" />
                                    </div>
                                  </div>
                                </div>
                              </TooltipWrapper>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <TooltipWrapper
                      content={`Удалить слово ${dictionaryCell?.word}`}
                      id={`dictionary_field_${index}`}
                    >
                      <div
                        onClick={(event) =>
                          openRemoveWordModal({ event, word: dictionaryCell?.word })
                        }
                        className="group rounded-r-defaultR z-10 p-[10px] text-right hover:bg-basic_red text-basic_red h-full transition hover:text-white flex items-center justify-between"
                      >
                        <CircleButton
                          className="text-basic_red group-hover:text-white"
                          icon="TrashIcon"
                          size={19}
                        />
                      </div>
                    </TooltipWrapper>
                  </div>
                </Box>
              );
            })}
        </div>
      )}
      {!preloadFileFlag &&
        Number(dictionaryFromServer?.total) >
          (paginationState?.limit || DEFAULT_PAGINATION.limit || 0) && (
          <div className="w-full my-[20px]">
            <Paginator
              page={
                Math.ceil(
                  paginationState?.offset ||
                    DEFAULT_PAGINATION.offset ||
                    10 / (paginationState?.limit || DEFAULT_PAGINATION.limit || 10),
                ) || 1
              }
              limit={paginationState.limit}
              count={dictionaryFromServer?.total || 0}
              onChange={changePaginateParamsHandler}
            />
          </div>
        )}
      <EasyModal
        show={!!removeWord}
        onClose={setRemoveWord.bind(null, null)}
        variant="removal"
        removeText={`Удалить ${removeWord}?`}
        onRemove={() => removeWordHandler()}
        withoutFooter
      >
        <div className="w-full inline-flex items-center font-bold  text-[18px] justify-center">
          {t('pages.editor.modal.delete_confirmation')}
        </div>
      </EasyModal>
    </div>
  );
};

export default EditorPage;
