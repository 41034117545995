import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';

import authApi from '@/store/api/auth.api';
import dictionaryApi from '@/store/api/dictionary.api';
import editorApi from '@/store/api/editor.api';
import modelApi from '@/store/api/model.api';
import ngrammApi from '@/store/api/ngramm.api';
import authSlice from '@/store/reducers/auth.slice';
import { userInfoApi } from '@/store/api/user-info.api';
import phonemizerApi from '@/store/api/phonomizer.api';

const store = configureStore({
  reducer: {
    authState: authSlice,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [editorApi.reducerPath]: editorApi.reducer,
    [modelApi.reducerPath]: modelApi.reducer,
    [phonemizerApi.reducerPath]: phonemizerApi.reducer,
    [ngrammApi.reducerPath]: ngrammApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      dictionaryApi.middleware,
      modelApi.middleware,
      ngrammApi.middleware,
      editorApi.middleware,
      userInfoApi.middleware,
      phonemizerApi.middleware,
    ),
});
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
