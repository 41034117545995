export const AUTH_KEY = 'REACT_TOKEN_AUTH_KEY';
export const LAST_URL = 'LAST_URL';
export const accessToken = 'access_token';
export const refreshToken = 'refresh_token';

export const PHONEMES_LANG = [
  'a',
  'zj',
  't',
  'l',
  'kj',
  's',
  'g',
  'z',
  'dj',
  'pj',
  'Ix',
  'f',
  'tc',
  'tj',
  'r',
  'nj',
  'dK&',
  'lj',
  'bj',
  'gj',
  'b',
  'tKs&',
  'dc',
  'xj',
  'p',
  'x',
  'I',
  'e',
  'tSj',
  'u',
  'o',
  'S',
  'n',
  'sj',
  'ax',
  'vj',
  'ix',
  'Sj',
  'k',
  'Z',
  'mj',
  'j',
  'm',
  'rj',
  'v',
  'i',
  'fj',
];

export const DEFAULT_PHONEMIZER_LANG = 'ru';
export const ARRAY_FIELD_NAME = 'ngrams';
export const NGRAM_VALIDATION_WEIGHT = {
  // [MIN, MAX]
  '1': [-1, -10],
  '2': [-0.1, -10],
  '3': [-0.01, -10],
};
export const DEFAULT_VALIDATION_WEIGHT = [-5, -3.5, -2.5];
