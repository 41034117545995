import React, { ChangeEvent, FC, useState } from 'react';
import { v4 } from 'uuid';

import { DownloadArrowIcon } from '@icons';

import { uploadButtonClassName } from './upload-button.styles';

type UploadButtonProps = {
  label: string;
  onUploadFile: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};
const UploadButton: FC<UploadButtonProps> = (props) => {
  const { onUploadFile, label, disabled = false } = props;
  const [fileName, setFileName] = useState<string | null>(null);
  const inputID = v4();
  function onUploadFileHandler(inputEvent: ChangeEvent<HTMLInputElement>) {
    if (inputEvent.target.files?.length) {
      setFileName(`${inputEvent.target.files[0].name} (${inputEvent.target.files[0].size} Kb)`);
      onUploadFile(inputEvent);
    } else {
      setFileName(null);
    }
  }
  return (
    <label htmlFor={inputID}>
      <div className="flex flex-col">
        <input
          id={inputID}
          type="file"
          name="load file"
          className="hidden appearance-none"
          onChange={onUploadFileHandler}
        />
        <div className={uploadButtonClassName(disabled)}>
          <DownloadArrowIcon size={14} className="text-white" />
          {label}
        </div>
        <div className="text-[10px] text-gray">{fileName}</div>
      </div>
    </label>
  );
};

export default UploadButton;
