import cn from 'classnames';

export const uploadButtonClassName = (disabled: boolean) =>
  cn(
    disabled ? 'opacity-60' : 'opacity-80 hover:opacity-100',
    'gap-x-[10px]',
    'p-[10px]',
    'rounded-[10px]',
    'cursor-pointer',
    'text-[15px]',
    'font-bold',
    'transition',
    'flex',
    'items-center',
    'justify-center',
    'border-[1px]',
    'border-action',
    'bg-action',
    'text-white',
    'h-[34px]',
  );
