import React from 'react';
import { BrokenRobotIcon } from '@icons';
import { Input } from '@ui';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import navigationRoutes from '@/utils/navigation-routes';
type HashForm = {
  hash: string;
};
const NotAuthBlock = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HashForm>();

  function submitHashHandler(data: HashForm) {
    navigate(`${navigationRoutes.hashAuth}?hash=${data.hash}`);
  }
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex items-center justify-center flex-col">
        <div>
          <BrokenRobotIcon size={50} className="text-action" />{' '}
        </div>
        <div className="text-sm text-3color">Вы не авторизованы...</div>
        <form onSubmit={handleSubmit(submitHashHandler)}>
          <Input
            placeholder="Укажите hash"
            error={errors.hash?.message}
            {...register('hash', {
              required: { value: true, message: 'Поле не может быть пустым' },
            })}
          />
        </form>
      </div>
    </div>
  );
};

export default NotAuthBlock;
