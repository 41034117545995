import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Loading } from '@ui';

import { useCheckSwitchEditorQuery, useSwitchEditorMutation } from '@/store/api/editor.api';
import navigationRoutes from '@/utils/navigation-routes';

const EditorSwitchPage = () => {
  const { model_id } = useParams();
  const navigate = useNavigate();
  const {
    data: checkModelToEdit,
    isLoading: isLoadingCheckEditor,
    isFetching: isFetchingCheckEditor,
  } = useCheckSwitchEditorQuery(null, {
    skip: !model_id,
  });
  const [switchEditorToModel] = useSwitchEditorMutation();
  const isCheckingEditorProcess = isLoadingCheckEditor || isFetchingCheckEditor;

  useEffect(() => {
    if (model_id && checkModelToEdit?.can_switch) {
      switchEditorToModel({ model_id })
        .unwrap()
        .then(() => {
          navigate(navigationRoutes.editor);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [checkModelToEdit?.can_switch, model_id, navigate, switchEditorToModel]);

  if (isCheckingEditorProcess) return <Loading text="Переключение модели" />;

  return (
    <div className="w-full h-full flex items-center justify-center text-2color">
      Не удалось открыть модель &nbsp;<b> {model_id} </b> &nbsp; для редактирования
    </div>
  );
};

export default EditorSwitchPage;
