import React, { FC } from 'react';

import { IconType } from '@/components/ui/icons/icon.type';

const CirclePlusIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
      >
        <g clipPath="url(#clip0_5_4829)">
          <path
            fill="currentColor"
            d="M24 15.625v.75c0 .413-.337.75-.75.75h-6.125v6.125c0 .413-.337.75-.75.75h-.75a.752.752 0 01-.75-.75v-6.125H8.75a.752.752 0 01-.75-.75v-.75c0-.412.338-.75.75-.75h6.125V8.75c0-.412.338-.75.75-.75h.75c.413 0 .75.338.75.75v6.125h6.125c.413 0 .75.338.75.75zm7.5.375c0 8.563-6.938 15.5-15.5 15.5C7.437 31.5.5 24.562.5 16 .5 7.437 7.438.5 16 .5 24.563.5 31.5 7.438 31.5 16zm-2 0c0-7.494-6.081-13.5-13.5-13.5C8.506 2.5 2.5 8.581 2.5 16c0 7.494 6.081 13.5 13.5 13.5 7.494 0 13.5-6.081 13.5-13.5z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_5_4829">
            <path fill="currentColor" d="M0 0H32V32H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CirclePlusIcon;
