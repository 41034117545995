import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/store/base-query';
import serverRoutes from '@/utils/server-routes';
import { CanSwitchEditor, CheckEditorModelId } from '@/interfaces/editor.type';

const editorApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'editorApi',
  tagTypes: ['MODEL_EDITOR'],
  endpoints: (build) => ({
    /* Проверка возможности переключения редактора на другую модель распознавания */
    checkSwitchEditor: build.query<CanSwitchEditor, void | null>({
      query: () => ({
        url: serverRoutes.editorSwitch,
      }),
      providesTags: ['MODEL_EDITOR'],
    }),
    /* Переключение редактора на другую модель распознавания */
    switchEditor: build.mutation<void, { model_id: string }>({
      query: (params) => ({
        url: serverRoutes.editorSwitch,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['MODEL_EDITOR'],
    }),
    /* Получение ID моделей которые в настоящий момент находится в редакторе */
    checkEditorModelId: build.query<CheckEditorModelId, void>({
      query: () => ({
        url: serverRoutes.umodel,
      }),
      providesTags: ['MODEL_EDITOR'],
    }),
  }),
});

export const { useCheckSwitchEditorQuery, useSwitchEditorMutation, useCheckEditorModelIdQuery } =
  editorApi;
export default editorApi;
