import React, { FC } from 'react';

import classNames from 'classnames';

import { IconType } from '@/components/ui/icons/icon.type';

const ParentTitle: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 18">
        <path
          fill="currentColor"
          d="M7.365 2.011c-.276.364-.113.81.34.81h2.592c.477 0 .621-.452.351-.81L9.348.285a.423.423 0 00-.69 0L7.365 2.01zm1.639 15.883c4.47 0 8.104-3.628 8.104-8.104 0-3.541-2.279-6.555-5.443-7.66.082.415-.025.823-.276 1.118a6.962 6.962 0 014.583 6.542 6.973 6.973 0 01-6.968 6.968c-3.849 0-6.975-3.126-6.975-6.968a6.953 6.953 0 014.595-6.542 1.332 1.332 0 01-.276-1.117A8.107 8.107 0 00.893 9.79a8.106 8.106 0 008.11 8.104zm0-2.536a5.57 5.57 0 005.562-5.568 5.566 5.566 0 10-11.13 0 5.572 5.572 0 005.568 5.568zM7.39 12.633c-.364 0-.565-.25-.565-.64V7.617c0-.414.207-.678.61-.678h1.744c1.3 0 2.097.697 2.097 1.827 0 .766-.458 1.4-1.168 1.633l.867 1.337a.69.69 0 01.125.402c0 .295-.22.495-.527.495-.257 0-.395-.106-.577-.395l-1.011-1.651H7.968v1.406c0 .396-.207.64-.578.64zm.578-2.887h1.048c.666 0 1.092-.346 1.092-.955 0-.621-.433-.979-1.073-.979H7.968v1.934z"
        ></path>
        <path
          fill="#5D697E"
          fillRule="evenodd"
          d="M7.39 12.633c-.364 0-.565-.25-.565-.64V7.617c0-.414.207-.678.61-.678h1.744c1.3 0 2.097.697 2.097 1.827 0 .766-.458 1.4-1.168 1.633l.867 1.337a.69.69 0 01.125.402c0 .295-.22.495-.527.495-.257 0-.395-.106-.577-.395l-1.011-1.651H7.968v1.406c0 .396-.207.64-.578.64zm1.626-2.887H7.968V7.812h1.067c.64 0 1.073.358 1.073.98 0 .608-.426.954-1.092.954z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M7.813 12h.68v-1.498h.951c.863 0 1.455-.586 1.455-1.443v-.006c0-.86-.592-1.437-1.455-1.437H7.812V12zm1.464-3.81c.59 0 .93.319.93.866v.006c0 .55-.34.869-.93.869h-.784V8.19h.784z"
        ></path>
      </svg>
    </div>
  );
};

export default ParentTitle;
