import React, { useMemo, useState, useEffect } from 'react';
import { PageHeader, Input, Button, RubberTable, Paginator } from '@ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import mock from './mock.json';
import modelmock from './model.mock.json';

import { useCurrentEditingWordsQuery } from '@/store/api/dictionary.api';

const ModelViewPage = () => {
  const { model_id } = useParams();
  const { t } = useTranslation();
  const model = useMemo(
    () => mock.find((item) => item.model_id.toString() === model_id),
    [model_id],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const defaultSortDesc = false;
  const defaultLimit = 100;
  const defaultOffset = 0;
  const defaultFilter = '';

  const paginationRequest = {
    sortDesc: defaultSortDesc,
    limit: defaultLimit,
    offset: defaultOffset,
    filter: defaultFilter,
    model_id: model_id,
  };

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData.offset / limit + 1);
  };

  const { data: CurrentEditingWordsData } = useCurrentEditingWordsQuery(paginationRequest);

  useEffect(() => {
    if (CurrentEditingWordsData) {
      console.log('Данные из CurrentEditingWordsData:', CurrentEditingWordsData);
    }
  }, [CurrentEditingWordsData]);
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * limit;
    const endIndex = startIndex + limit;
    return modelmock.slice(startIndex, endIndex);
  }, [currentPage, limit]);

  const columns = [
    {
      title: t('pages.modelViewPage.tabel.word'),
      index: 'word',
    },
    {
      title: t('pages.modelViewPage.tabel.phonogramm'),
      index: 'phonogramm',
    },
    {
      title: t('pages.modelViewPage.tabel.wordweight'),
      index: 'wordweight',
    },
    {
      title: t('pages.modelViewPage.tabel.ngram'),
      index: 'ngramm',
      maxWidth: 150,
    },
  ];

  return (
    <div>
      <PageHeader label={model?.name} icon="FolderMediaIcon" />
      <div className="bg-primary h-[120px] w-full rounded-[10px]">
        <div className="ml-[2px] p-[10px] bg-white h-[120px] w-full rounded-[10px]">
          <h1 className="mb-[6px] font-bold text-[20px]">
            {t('pages.modelViewPage.filter.findword')}
          </h1>
          <div className="flex gap-2">
            <Input />
            <Button label={t('pages.modelViewPage.filter.serchbutton')} />
          </div>
        </div>
      </div>
      <div>
        <RubberTable dataSource={paginatedData} columns={columns} />
        <Paginator count={modelmock.length} onChange={handlePageChange} page={currentPage} />
      </div>
    </div>
  );
};

export default ModelViewPage;
